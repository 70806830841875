if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;

        do {
            if (Element.prototype.matches.call(el, s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

addScriptToQueue(() => {
    const style = document.createElement("link");
    style.rel = "stylesheet";
    style.href = "/js/fancybox/jquery.fancybox.min.css";
    document.head.append(style);

    require('./src/jquery.fancybox.js');
}, 0);

addScriptToQueue(() => {
    require('./src/script-p1');
}, 0);
