// var CrossStorage = null;

window.college_icon = "//www.uloop.com/img/map/college_marker.png";
window.ads_icon = "//www.uloop.com/img/map/green_marker.png";

window.iphone = false;

window.results_container = false;
window.classifieds_container = false;
window.news_container = false;
window.plugin_fast_search_run = false;
window.fast_search_run = false;
window.results_location_container = false;
window.search_timer = false;
window.search_value = '';
window.search_row_index = -1;
window.imagesDomain = '//doikmin2nht2v.cloudfront.net';

window.locationSearchTimeout = false;
window.location_search_xhr = false;

// window.location_change = '';

// var mlsd_type = '';
// var mlsd_id = '';

window.autoSearchAds = false;

window.crossDomainResponse;
window.CommentsUploadSWF;

window.MainWindowDocument = window.parent.document;
window.MainWindow = window.parent;
window.BlankWindow = null;

window.SocketServer = null;

let days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
let months = ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sept','Oct','Nov','Dec'];

/**
 *
 * Fix issue with SVG for IE
 *
 */

window.SVGUseSupport = true;

window.sign_up_today_popup_obj = null;

window.share_and_feature_popup_obj = null;

window.ConversionTracking_actions_in_process = 0;

window.m_w_width = jQuery(window).width();

window.WPKeepAliveTimer = 0;

window.OpenBlankWindowTimeout = false;

window.parentElem = '';


window.search_location_row_index = -1;
window.search_xhr = false;
window.map_view_custom_draw = false;
window.map_view_zoom = false;
window.MainPage = jQuery("html, body", window.MainWindowDocument);
window.DomainView = false;

window.isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function () {
        return (window.isMobile.Android() || window.isMobile.BlackBerry() || window.isMobile.iOS() || window.isMobile.Opera() || window.isMobile.Windows());
    }
};

window.CurrentUser = new function () {
    this.getId = function () {
        return getCookie('logged_user_id');
    };
    this.getFirstName = function () {
        return getCookie('logged_user_first_name');
    };
    this.getLastName = function () {
        return getCookie('logged_user_last_name');
    };
    this.getEmail = function () {
        return getCookie('logged_user_email');
    };
    this.canPostFreeAds = function () {
        return getCookie('logged_user_can_post_free_ads');
    };
    this.isAdmin = function () {
        return getCookie('logged_user_is_admin');
    };

    this.logoutUser = function () {
        setCookie('login_status', '', {expires: -1, path: '/'});
        setCookie('logged_user_id', '', {expires: -1, path: '/'});
        setCookie('logged_user_first_name', '', {expires: -1, path: '/'});
        setCookie('logged_user_last_name', '', {expires: -1, path: '/'});
        setCookie('logged_user_email', '', {expires: -1, path: '/'});
        setCookie('logged_user_can_post_free_ads', '', {expires: -1, path: '/'});
        setCookie('logged_user_is_admin', '', {expires: -1, path: '/'});
    }
}

window.QueryString = function () {
    // This function is anonymous, is executed immediately and
    // the return value is assigned to QueryString!
    var query_string = {};
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        // If first entry with this name
        if (typeof query_string[pair[0]] === "undefined") {
            query_string[pair[0]] = decodeURIComponent(pair[1]);
            // If second entry with this name
        } else if (typeof query_string[pair[0]] === "string") {
            var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
            query_string[pair[0]] = arr;
            // If third or later entry with this name
        } else {
            query_string[pair[0]].push(decodeURIComponent(pair[1]));
        }
    }
    return query_string;
}();


window._toArray = function (arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
};

window.CrossDomainStorage = (function () {
    let CrossDomainStorage = function CrossDomainStorage(config) {
        if (!config.origin) throw "URL is not specified";
        this.origin = config.origin;
        this.path = config.path || '';
        this.url = this.origin + this.path;
        this.documentObj = false;
        this.ready = false;
        this.queue = [];
        this.reqId = 0;
        this.callbacks = {};

        this.init();
    };

    CrossDomainStorage.prototype.init = function () {
        var _this = this;
        if (!this.path) {
            _this._ready();

            on(window, "message", function (event) {
                return _this._onMessage(event);
            });
        }
        else {
            var iframe = document.createElement("iframe");
            iframe.src = this.url;
            iframe.style.cssText = "position:absolute;width:1px;height:1px;left:-9999px;border:none;bottom:0;";
            document.body.appendChild(iframe);

            on(iframe, "load", function () {
                return _this._ready();
            });
            on(window, "message", function (event) {
                return _this._onMessage(event);
            });

            this.documentObj = iframe;
        }
    };

    CrossDomainStorage.prototype.set = function (data, cb) {
        if (!data) return;
        this._send({method: "set", data: data}, cb);
    };

    CrossDomainStorage.prototype.get = function (key, cb) {
        key = key || "";
        this._send({method: "get", data: key}, cb);
    };

    CrossDomainStorage.prototype.remove = function (key, cb) {
        key = key || "";
        this._send({method: "remove", data: key}, cb);
    };

    CrossDomainStorage.prototype._send = function (msg, cb) {
        if (typeof(cb) === undefined) cb = function () {
            return false;
        };
        if (!this.ready) return this._queue(msg.method, [msg.data, cb]);

        this.reqId++;
        this.callbacks[this.reqId] = cb;

        msg.id = this.reqId;
        if (!this.path) {
            switch (msg.method) {
                case 'get':
                    var data = {};
                    if (msg.data) {
                        data = localStorage.getItem(msg.data);
                    } else {
                        data = {};
                        for (var i in localStorage) data[i] = localStorage.getItem(i);
                    }
                    typeof(cb) !== 'undefined' && cb(data);
                    delete this.callbacks[msg.id];
                    break;

                case 'set':
                    var data = msg.data || {};
                    for (var i in data) {
                        if (typeof localStorage[i] == "undefined") localStorage[i] = "";
                        if (typeof data[i] == "object") data[i] = JSON.stringify(data[i]);
                        localStorage.setItem(i, data[i]);
                    }
                    typeof(cb) !== 'undefined' && cb(JSON.stringify({id: msg.id}));
                    delete this.callbacks[msg.id];
                    break;
            }
        }
        else {
            this.documentObj.contentWindow.postMessage(JSON.stringify(msg), this.url);
        }
    };

    CrossDomainStorage.prototype._queue = function (method, args) {
        this.queue.push(arguments);
    };

    CrossDomainStorage.prototype._ready = function () {
        this.ready = true;

        if (!this.queue.length) return;

        // if (typeof Symbol !== typeof undefined)
        {
            // for (var _iterator = this.queue[Symbol.iterator](), _step; !(_step = _iterator.next()).done;) {
            //     var call = _step.value;
            //     var method = call[0];
            //     var args = call[1];
            //
            //     if (!this[method]) continue;
            //     this[method].apply(this, window._toArray(args));
            // }


            for (var key in this.queue) {
                var call = this.queue[key];
                var method = call[0];
                var args = call[1];

                if (!this[method]) continue;
                this[method].apply(this, window._toArray(args));
            }
        }

        this.queue = [];
    };

    CrossDomainStorage.prototype._onMessage = function (event) {
        if (this.origin != event.origin) return;

        var msg;

        try {
            msg = JSON.parse(event.data);
            var cb = this.callbacks[msg.id];
            typeof(cb) !== 'undefined' && cb(msg.data);
            delete this.callbacks[msg.id];
        } catch (e) {
        }
    };

    return CrossDomainStorage;
})();

function detectIE() {
    var ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/left_content7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}

/**
 *
 * Adapter for different platforms (WP Plugin etc)
 *
 */
window.CodeAdapter = new function () {

    this.project_type = 'uloop';
    this.plugin_param = 'usatoday_api=true';

    if (typeof(plugin_data) !== 'undefined') {
        this.project_type = 'wordpress_plugin';
        if (typeof(plugin_data.plugin_param) !== 'undefined') {
            this.plugin_param = plugin_data.plugin_param;
        }
    }

    this.first_step_initialized = false;
    this.page_with_required_login = false;

    this.ajaxRequest = function (options) {
        if (this.project_type === 'wordpress_plugin') {
            if (options.url.length === 0 || options.url.charAt(0) === '/') {
                options.url = plugin_data.uloop_base_domain + options.url;
            } else {
                options.url = plugin_data.uloop_base_domain + '/' + options.url;
            }
            CDR().request(options);
            return window.crossDomainResponse;
        } else {
            return jQuery.ajax(options);
        }
    };

    this.ajaxAbortRequest = function (options) {
        if (this.project_type === 'wordpress_plugin') {

        } else {
            options.ajax.abort();
        }
    };

    this.html5ImageUploadIsAllowed = function () {
        if (this.project_type === 'uloop') {
            return true;
        }
        else if (this.project_type === 'wordpress_plugin') {
            return true;
        }
        else {
            return false;
        }
    };

    this.shouldInitFirstStepOnDocumentReady = function () {
        if (this.project_type === 'wordpress_plugin' && this.first_step_initialized === false) {
            this.first_step_initialized = true;
            return true;
        } else {
            return false;
        }
    };

    this.initWordpressPlugin = function () {

        if (this.project_type !== 'wordpress_plugin') {
            return false;
        }

        window.uloop_current_page = 1;
        window.uloop_can_load_next_page = true;
        window.imagesDomain = plugin_data.uloop_base_domain;

        window.session_name = 'ULOOP_SESSID';

        if (getCookie(window.session_name) === undefined) {
            setCookie(window.session_name, generate_session_id(), {expires: 0, path: '/'});
        }

    };

    this.wordpressDocumentReady = function () {

        var urlSchema = urlObject();

        if (this.project_type !== 'wordpress_plugin') {
            return false;
        }


        //Add hotlist stars to news
//		jQuery('article.block.big').each(function(index,element){
//			jQuery(element).find('.content').append('<div class="uloop-hotlist-start" id="hotlist-'+jQuery(element).find('input[name="article_id"]').val()+'"></div>');
//		});
//
//		jQuery('.uloop-hotlist-start').on('click',function(){
//			USATNewsAddToHotlist(jQuery(this).attr('id').replace('hotlist-',''));
//			return false;
//		});
//
//		var MainWindowDocument = window.parent.document;
//         var MainWindow = window.parent;

        jQuery('.search-icon').click(function () {
            //if(jQuery('#searchform input').is(':visible') ){
            jQuery('.school-title-container').hide();
            //} else {
            //}
        });
        jQuery('#searchform input').blur(function () {
            setTimeout(function () {
                jQuery('.school-title-container').show();
            }, 700);


        });
        if (urlSchema.parameters.housing && urlSchema.parameters.housing == 1) {
            jQuery('.jobs-post-buttons-hide').hide();
            jQuery('.orange_button_h_36.jobs-post-buttons-hide').css('display', 'none');
        } else {
            jQuery('.jobs-post-buttons-hide').show();
            jQuery('.orange_button_h_36.jobs-post-buttons-hide').css('display', 'block');
        }


        jQuery('input[name="online"]').change(function () {
            if (jQuery(this).val() == 1) {
                if (plugin_data.uloop_university_id > 0) {
                    setCookie('uloop-tutors-domain', plugin_data.uloop_university_slug ? plugin_data.uloop_university_slug : 'student', {
                        expires: 60 * 60 * 24,
                        path: '/'
                    });
                }
                window.location = '/' + 'student' + '/' + 'online-tutors';
            } else {
                if (getCookie('uloop-tutors-domain')) {
                    var redirect = '/' + ( getCookie('uloop-tutors-domain') && getCookie('uloop-tutors-domain') !== 'false' ? getCookie('uloop-tutors-domain') : 'student' ) + '/' + 'tutors';
                    setCookie('uloop-tutors-domain', false, {expires: 60 * 60 * 24, path: '/'});
                    window.location = redirect;
                } else {
                    window.location = '/' + ( plugin_data.uloop_university_slug ? plugin_data.uloop_university_slug : 'student' ) + '/' + 'tutors';
                }
            }
        });

        jQuery('.menu-online-tutor, .menu-tutor').click(function () {

            if (jQuery(this).attr('class') == 'menu-online-tutor') {
                if (plugin_data.uloop_university_id > 0) {
                    setCookie('uloop-tutors-domain', plugin_data.uloop_university_slug ? plugin_data.uloop_university_slug : 'student', {
                        expires: 60 * 60 * 24,
                        path: '/'
                    });
                }
                window.location = '/' + 'student' + '/' + 'online-tutors';
            } else {
                if (getCookie('uloop-tutors-domain')) {
                    var redirect = '/' + ( getCookie('uloop-tutors-domain') && getCookie('uloop-tutors-domain') !== 'false' ? getCookie('uloop-tutors-domain') : 'student' ) + '/' + 'tutors';
                    setCookie('uloop-tutors-domain', false, {expires: 60 * 60 * 24, path: '/'});
                    window.location = redirect;
                } else {
                    window.location = '/' + ( plugin_data.uloop_university_slug ? plugin_data.uloop_university_slug : 'student' ) + '/' + 'tutors';
                }
            }
            return false;
        });

        if (plugin_data.uloop_university_slug !== 'student' && plugin_data.uloop_university_slug !== 'order-summary') {
            jQuery('.header-holder .logo a').attr('href', jQuery('.header-holder .logo a').attr('href') + plugin_data.uloop_university_slug + '/');
        }

        jQuery('.heading').after(jQuery("#secondary"));

        jQuery(window).resize(function () {
            if (jQuery(window).width() > 727) {
                jQuery('.uloop-widget').css('display', 'block');
                jQuery('.uloop-widget').removeClass('active');
                jQuery('.heading-small h2').removeClass('active');
            } else if (!jQuery('.uloop-widget').hasClass('active')) {
                jQuery('.uloop-widget').css('display', 'none');
            }
        });

        if (jQuery('#pages-scroll-indicator').get(0)) {
            jQuery(window).scroll(function () {

                if (elementInViewport(jQuery('#pages-scroll-indicator').get(0))) {
                    load_next_page();
                }
            });
        }

        jQuery('.listing-item .wrap-image img, .detail_main_image img').error(function () {
            jQuery(this).attr('src', window.imagesDomain + '/img/no-image-140-140.jpg');
        });
        jQuery('.textbook_detail .tb-d-img img').each(function () {
            if (this.complete && ( this.naturalHeight === 0 || this.naturalWidth === 0 )) {
                errorTextBookCover(this);
            }
            jQuery(this).attr('onerror', 'errorTextBookCover(this);');
        });
        jQuery('.textbook .tb-img img').each(function () {
            if (this.complete && ( this.naturalHeight === 0 || this.naturalWidth === 0 )) {
                errorTextBookCover(this);
            }
            jQuery(this).attr('onerror', 'errorTextBookCover(this);');
        });

        jQuery('.heading-small').click(function () {

            if (jQuery(window).width() <= 727) {

                if (!jQuery('.uloop-widget').is(':visible')) {
                    jQuery('.heading-small h2').addClass('active');
                    jQuery('.uloop-widget').addClass('active');
                } else {
                    jQuery('.heading-small h2').removeClass('active');
                    jQuery('.uloop-widget').removeClass('active');
                }
                jQuery('.uloop-widget').slideToggle();

            }

        });


        if (jQ(".reply").get(0)) {

            var partner_code = 0;
            var external_transition = 0;
            if (urlSchema.parameters.tlink) {
                partner_code = urlSchema.parameters.tlink;
                external_transition = 1;
            }
            var referer = document.referrer;
            jQ('.reply input[name="partner_code"]').val(partner_code);
            jQ('.reply input[name="external_transition"]').val(external_transition);
            jQ('.reply input[name="referer"]').val(referer);
        }

        if (urlSchema.parameters.elink && urlSchema.parameters.outID) {
            trackTransitionFromEmail(urlSchema.parameters.outID);
        }


        jQuery('.sidebar_subtittle:not(.always_active)').click(function () {

            jQuery('.sidebar_subtittle').removeClass('active');
            jQuery('.categories_items:not(.always_visible)').slideUp("fast");
            var _this = this;
            if (!jQuery(this).next().is(":visible")) {
                jQuery(this).addClass('active');
                jQuery(this).next().slideToggle("fast", function () {
                    if (!elementInViewport(jQuery(_this).get(0))) {
                        jQuery('html, body').animate({
                            scrollTop: jQuery(_this).offset().top - 100
                        }, 300);
                    }
                });
            }

        });

        if (jQuery('.special_search .DatePicker').get(0)) {
            jQuery('.special_search .DatePicker').each(function (index, elem) {
                jQuery(elem).datepicker({
                    inline: true,
                    nextText: '&rarr;',
                    prevText: '&larr;',
                    showOtherMonths: true,
                    dateFormat: 'mm/dd/yy',
                    buttonImageOnly: false,
                });
            });
        }
        if (typeof UloopCustomSliderClass === 'function') {
            window.UloopDetailPageSlider = new UloopCustomSliderClass();
            UloopDetailPageSlider.SliderInit({
                slider: "#wrap_detail_slider",
                wrap_big_image: ".custom_page_big_image",
                wrap_preview: "#wrap_preview",
                use_preview: true
            });
        }

        this.processExternalLink();

        if (window.CurrentUser.getId()) {
            WPAttachKeepAliveTimer();
        }


    };

    this.processExternalLink = function () {
        var source = '';
        if (window.CodeAdapter.plugin_param === "universityparent_api=true") {
            source = 'universityparent';
        } else if (window.CodeAdapter.plugin_param === "collegeparentcentral_api=true") {
            source = 'collegeparentcentral';
        } else {
            source = 'usatoday';
        }

        jQuery("a[view-external-link]").each(function () {
            if (!jQuery(this).hasClass('hidden-tracking') || jQuery(this).attr('href') == '#') {
                jQuery(this).attr('href', plugin_data.uloop_base_domain + '/view_external_link.php?' + jQuery(this).attr('view-external-link') + '&source=' + source);
                if (jQuery(this).attr('target') !== '_self') {
                    jQuery(this).attr('rel', 'nofollow noopener noreferrer');
                    jQuery(this).attr('target', '_blank');
                }
            }
        });
    };

    this.addCustomCss = function (custom_css) {
        var css = document.createElement('style');
        css.setAttribute('type', 'text/css');
        css.innerHTML = custom_css;
        document.getElementsByTagName("head")[0].appendChild(css);
    };

    this.loadNextJavascriptFile = function () {
        if (this.customJsFilesArray.length) {
            this.addCustomJavascriptFile(this.customJsFilesArray.pop());
        }
    };

    this.addCustomJavascriptFile = function (js_file) {
        var js = document.createElement("script");
        js.src = js_file;
        js.type = "text/javascript";
        var _this = this;

        js.onload = function () {

            _this.loadNextJavascriptFile();

            window.CodeAdapter.customJsFilesLoadedCount++;
            if (window.CodeAdapter.customJsFilesLoadedCount === window.CodeAdapter.customJsFilesCount) {
                jQuery.holdReady(false);
            }
        };
        js.onerror = function () {
            _this.loadNextJavascriptFile();
            jQuery.holdReady(false);
        };
        document.getElementsByTagName("head")[0].appendChild(js);
    };

    this.customJsFilesCount = 0;
    this.customJsFilesLoadedCount = 0;
    this.customJsFilesArray = [];

    this.loadPageAjax = function (page_url, page_data) {

        page_data.load_ajax_page = 1;

        if (plugin_data.uloop_university_slug) {
            page_data.university_slug = plugin_data.uloop_university_slug;
        }
        if (plugin_data.uloop_category_slug) {
            page_data.category_slug = plugin_data.uloop_category_slug;
        }
        if (plugin_data.uloop_listing_slug) {
            page_data.listing_slug = plugin_data.uloop_listing_slug;
        }
        if (plugin_data.uloop_listing_id) {
            page_data.listing_id = plugin_data.uloop_listing_id;
        }
        var _this = this;
        window.CodeAdapter.ajaxRequest({
            url: page_url,
            data: page_data,
            dataType: "json",
            success: function (data) {
                jQuery("#ajax_page_content").html(data.content);
                if (data.widget_content && data.widget_content.length) {
                    jQuery("#ajax_widget_content").html(data.widget_content);
                }
                if (data.page_title && data.page_title.length) {
                    jQuery('.heading h1').html(data.page_title);
                }

                if (data.custom_css && data.custom_css.length) {
                    _this.addCustomCss(data.custom_css);
                }
                if (data.additional_javascript_files && data.additional_javascript_files.length) {
                    window.CodeAdapter.customJsFilesCount = data.additional_javascript_files.length;
                    for (var key in data.additional_javascript_files) {

                        _this.customJsFilesArray.push(data.additional_javascript_files[key]);
                        //	_this.addCustomJavascriptFile(data.additional_javascript_files[key]);
                    }
                    _this.customJsFilesArray = _this.customJsFilesArray.reverse();
                    _this.loadNextJavascriptFile();
                } else {
                    jQuery.holdReady(false);
                }
            }
        });
    };
};

function ConversionTracking() {

    var _this = this;

    this.triggerAction = function (params) {

        params.url = window.location.href;
        params.referrer = document.referrer;
        var actions = _this.unpackActionParams(getCookie('conversion_tracking_actions'));

        actions.push(params);
        setCookie('conversion_tracking_actions', _this.packActionParams(actions), {
            expires: 60 * 60 * 24,
            path: '/',
            domain: COOKIE_DOMAIN
        });

        _this.process();
    };

    this.triggerGoal = function (params) {

        params.url = window.location.href;
        params.referrer = document.referrer;
        var goals = _this.unpackActionParams(getCookie('conversion_tracking_goals'));
        goals.push(params);
        setCookie('conversion_tracking_goals', _this.packActionParams(goals), {
            expires: 60 * 60 * 24,
            path: '/',
            domain: COOKIE_DOMAIN
        });

        _this.process();
    };

    this.packActionParams = function (params) {
        return JSON.stringify(params);
    };

    this.unpackActionParams = function (param) {
        try {
            return JSON.parse(param);
        } catch (e) {
            return [];
        }
    };

    this.processActions = function() {
        var actions = _this.unpackActionParams(getCookie('conversion_tracking_actions'));

        while (actions.length) {
            var action_params = actions.pop();

            setCookie('conversion_tracking_actions', _this.packActionParams(actions), {
                expires: 60 * 60 * 24,
                path: '/',
                domain: COOKIE_DOMAIN
            });
            window.ConversionTracking_actions_in_process++;

            window.CodeAdapter.ajaxRequest({
                url: "ajaxapi.php",
                data: 'action=trigger_conversion_action&action_id=' +
                    action_params.action_id + '&url=' + action_params.url +
                    '&referrer=' + action_params.referrer + AJAXSessionParam,
                dataType: "html",
                type: "POST",
                cache: "false",
                success: function () {
                    window.ConversionTracking_actions_in_process--;
                    if (window.ConversionTracking_actions_in_process == 0) {
                        _this.processGoals();
                    }
                }
            });
        }
    };

    this.processGoals = function() {
        var goals = _this.unpackActionParams(getCookie('conversion_tracking_goals'));

        while (goals.length) {
            var goal_params = goals.pop();

            setCookie('conversion_tracking_goals', _this.packActionParams(goals), {
                expires: 60 * 60 * 24,
                path: '/',
                domain: COOKIE_DOMAIN
            });

            window.CodeAdapter.ajaxRequest({
                url: "ajaxapi.php",
                data: 'action=trigger_conversion_goal&goal_id=' + goal_params.goal_id + '&url=' + goal_params.url + '&referrer=' + goal_params.referrer + AJAXSessionParam,
                dataType: "html",
                type: "POST",
                cache: "false",
                success: function () {
                }
            });
        }
    };

    this.process = function () {
        _this.processActions();
        if (window.ConversionTracking_actions_in_process == 0) {
            _this.processGoals();
        }
    };

    this.setupHandlers = function () {
        var _this = this;
        jQ('body').on('click', '*[conversion-action]', function () {
            _this.triggerAction({
                action_id: jQ(this).attr('conversion-action')
            });
        });

        jQ('body').on('click', '*[conversion-goal]', function () {
            _this.triggerGoal({
                goal_id: jQ(this).attr('conversion-goal')
            });
        });
    };

    return this;
}

function fixSvgIE9(document, CACHE) {

    if(window.SVGUseSupport){
        return;
    }

    Array.prototype.forEach.call(document.querySelectorAll('use'), function (use) {

        var
            svg = use.parentNode,
            url = use.getAttribute('xlink:href').split('#'),
            url_root = url[0],
            url_hash = url[1],
            xhr = CACHE[url_root] = CACHE[url_root] || new XMLHttpRequest();

        if (!xhr.s) {
            xhr.s = [];

            xhr.open('GET', url_root);

            xhr.onload = function () {
                var x = document.createElement('x'), s = xhr.s;

                x.innerHTML = xhr.responseText;

                xhr.onload = function () {
                    s.splice(0).map(function (array) {
                        var g = x.querySelector('#' + array[2]);

                        if (g /*&& array[1].firstChild*/) {
                            array[0].replaceChild(g.cloneNode(true), array[1]);
                        }
                    });
                };

                xhr.onload();
            };

            xhr.send();
        }

        xhr.s.push([svg, use, url_hash]);

        // if (xhr.responseText) xhr.onload();
    });
}

function changeLocationPopup(modal, toCenter, callback) {
    var modal = typeof modal == 'boolean' ? modal : false;
    var toCenter = typeof toCenter == 'boolean' ? toCenter : true;
    requirejs(['popup/changeLocation'], function (change_location_form) {
        change_location_form.instance.open(modal, toCenter, callback);
    });
    return false;
}

//open Find Out More Popup
function openFindOutMorePopup() {
    jQ('body').on('click', '.top_reply a, .bottom_reply a, .sidebar_reply a', function () {

        requirejs(['popup/findOutMore'], function (find_out_more_popup) {
            find_out_more_popup.instance.open(false, true);
        });
    });
}


function findCollegeByEmail(event) {
    requirejs(['forms/main'], function (form) {
        form.instance.initialize({
            url: '/ajaxapi.php',
            action_event: event,
            scroll: false,
            callback: function(form, data){
                // if (typeof data.no_schools != typeof undefined) {
                //     jQ('form[name="location_search"]').eq(0).hide();
                //     jQ('form[name="location_search"]').eq(1).show();
                // } else {
                //     requirejs(['popup/signUpStudent'], function (sign_up) {
                //         sign_up.instance.open();
                //     });
                // }
            }
        });
        form.instance.submit();
    });
    return false;
}

function shareAndFeaturePopup() {
    requirejs(['popup/shareAndFeature'], function (share_and_feature_popup) {
        window.share_and_feature_popup_obj = share_and_feature_popup;
        window.share_and_feature_popup_obj.instance.open();
    });
}

function showSampleListingIMG(){
    if(window.CodeAdapter.project_type === 'wordpress_plugin'){
        if(jQ('#sampleListingIMG').length == 0) {
            var img = '';

            if (plugin_data.uloop_base_domain.indexOf('rent.') !== -1) {
                img = window.imagesDomain + '/img/screenshots/Rent-Sample-Listing.jpg';
            } else if (plugin_data.uloop_base_domain.indexOf('jobs.') !== -1) {
                img = window.imagesDomain + '/img/screenshots/Job-Sample-Listing.jpg';
            }

            jQ('.uloop-content').last().append('<div class="super_popup" id="sampleListingIMG"><img style="display: block; margin: 0 auto; padding: 10px; width: 500px; max-width: calc(100% - 20px);" src="' + img + '"></div>');
        }

        popup({
            popup_id: 'sampleListingIMG'
        });
    } else {
        requirejs(['popup/sampleListingIMG'], function (sample_listing_img_popup) {
            sample_listing_img_popup.instance.open();
        });
    }
}
function findLocation(event) {
    requirejs(['forms/main'], function (form) {
        form.instance.initialize({
            action_event: event,
            scroll: false,
            response_type: 'html',
            callback: function(form_elem, data) {
                if (typeof data != typeof undefined && data.length) {
                    if(window.location.host.split('.')[0] === 'www'){
                        if(window.location.pathname === '/'){
                            sendGoogleAnalytics('event', 'Student selected school', 'Homepage “School Selection”', 'from ' + window.location.href);
                        } else {
                            sendGoogleAnalytics('event', 'Student selected school', 'Category page “School Selection”', 'from ' + window.location.href);
                        }
                    }

                    if (jQ('input[name="redirect_url"]', form_elem).length) {
                        window.location = data + ('/' + jQ('input[name="redirect_url"]', form_elem).val()).replace(/\/\//g, "/")
                    } else {
                        window.location = data + '/' + window.location.pathname.substring(1);
                    }
                } else {
                    var error_field_elem = jQ('[name="location"][disabled!="disabled"]', form_elem).get(0);
                    form.instance.triggerError(error_field_elem, '', form.instance.options.scroll);
                }
            }
        });
        form.instance.submit();
    });
    return false;
}

function signUp(event, elem) {
    event.preventDefault();

    var $from_obj = jQ(elem);

    jQ(".btn-submit", $from_obj).prop("disabled", true).addClass("sending");

    requirejs(['forms/main'], function (form) {
        form.instance.initialize({
            url: $from_obj.attr("action"),
            action_elem: $from_obj,
            dynamic_fields: {json_response: "1", goal_action: "sign-up"},
            scroll: false,
            preload_container: ".action-buttons-bar .circle-preloader",
            callback: function (form, data) {
                jQ(".btn-submit", form).prop("disabled", false).removeClass("sending");

                if (typeof getCookie("sign-up-email") !== typeof undefined) {
                    setCookie("sign-up-email", "", {expires: -1, path: "/"});
                }

                window.location = '/sign-up';
            },
            error_callback: function (form, data) {
                jQ(".btn-submit", form).prop("disabled", false).removeClass("sending");
            }
        });
        form.instance.submit();
    });
    return false;
}

function trackClickAction(elem) {
    var action = jQ(elem).attr('click-action');

    var actions = getCookie('tracking_click_actions');
    try {
        actions = JSON.parse(actions);
    } catch (e) {
        actions = [];
    }

    actions.push({action: action, page_url: window.location.href, to_page: jQ(elem).attr('href')});
    setCookie('tracking_click_actions', JSON.stringify(actions), {
        expires: 60 * 60 * 24,
        path: '/',
        domain: COOKIE_DOMAIN
    });

    if (typeof(jQ(elem).attr('target')) != 'undefined') {
        processTrackClickAction();
    } else {
        setTimeout(processTrackClickAction, 500);
    }
}

function processTrackClickAction() {

    var actions = getCookie('tracking_click_actions');

    window.CodeAdapter.ajaxRequest({
        url: "ajax_content.php",
        data: 'action=tracking_click_action&tracking_actions=' + actions,
        dataType: "html",
        type: "POST",
        cache: "false",
        success: function (data) {
            setCookie('tracking_click_actions', '', {expires: -1, path: '/', domain: COOKIE_DOMAIN});
        }
    });
}

function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}

function on(el, event, cb) {
    if (document.addEventListener) {
        el.addEventListener(event, cb, false);
    } else {
        el.attachEvent("on" + event, cb);
    }
}
/**
 *
 * Cross domain storage END
 *
 */


function urlObject(options) {
    "use strict";
    /*global window, document*/

    var url_search_arr,
        option_key,
        i,
        urlObj,
        get_param,
        key,
        val,
        url_query,
        url_get_params = {},
        a = document.createElement('a'),
        default_options = {
            'url': window.location.href,
            'unescape': true,
            'convert_num': true
        };

    if (typeof options !== "object") {
        options = default_options;
    } else {
        for (option_key in default_options) {
            if (default_options.hasOwnProperty(option_key)) {
                if (options[option_key] === undefined) {
                    options[option_key] = default_options[option_key];
                }
            }
        }
    }

    a.href = options.url;
    url_query = a.search.substring(1);
    url_search_arr = url_query.split('&');

    if (url_search_arr[0].length > 1) {
        for (i = 0; i < url_search_arr.length; i += 1) {
            get_param = url_search_arr[i].split("=");

            if (options.unescape) {
                key = decodeURI(get_param[0]);
                val = decodeURI(get_param[1]);
            } else {
                key = get_param[0];
                val = get_param[1];
            }

            if (options.convert_num) {
                if (val.match(/^\d+$/)) {
                    val = parseInt(val, 10);
                } else if (val.match(/^\d+\.\d+$/)) {
                    val = parseFloat(val);
                }
            }

            if (url_get_params[key] === undefined) {
                url_get_params[key] = val;
            } else if (typeof url_get_params[key] === "string") {
                url_get_params[key] = [url_get_params[key], val];
            } else {
                //url_get_params[key].push(val);
            }

            get_param = [];
        }
    }

    urlObj = {
        protocol: a.protocol,
        hostname: a.hostname,
        host: a.host,
        port: a.port,
        hash: a.hash.substr(1),
        pathname: a.pathname,
        search: a.search,
        parameters: url_get_params
    };

    return urlObj;
}

function getElementsByClassNameIESupport(node, classname) {
    if (node.getElementsByClassName) { // use native implementation if available
        return node.getElementsByClassName(classname);
    } else {
        return (function getElementsByClass(searchClass, node) {
            if (node == null)
                node = document;
            var classElements = [],
                els = node.getElementsByTagName("*"),
                elsLen = els.length,
                pattern = new RegExp("(^|\\s)" + searchClass + "(\\s|$)"), i, j;

            for (i = 0, j = 0; i < elsLen; i++) {
                if (pattern.test(els[i].className)) {
                    classElements[j] = els[i];
                    j++;
                }
            }
            return classElements;
        })(classname, node);
    }
}


function processWidgetsJsonUloop() {
    jQ("#right_content .right_links_item a[data-index], #right_content .right_content_list a[data-index]").each(function (index, elem) {
        var data_index = jQ(elem).attr('data-index');

        if (typeof data_index !== typeof undefined && data_index !== false) {
            jQ(elem).attr('data', data_index).removeAttr('data-index');
            processElemetAttributesFromJsonUloop(elem);
        }
    });
}

function processElemetAttributesFromJsonUloop(elem) {
    var pluginData = jQ(elem).attr('data');
    if (typeof(pluginData) !== 'undefined') {
        var pluginData = jQ(elem).attr('data').replace(/=/g, ":");
        try {
            pluginData = JSON.parse(pluginData);
            if (pluginData && typeof pluginData === 'object') {
                jQ(elem).removeAttr('data');
                for (key in pluginData) {
                    jQ(elem).attr(key, pluginData[key]);
                }
            }
        } catch (e) {
        }
    }
}

/**
 *
 * WP Plugin JS
 *
 */

/*
 *
 * Hotlist functions
 *
 */

function USATNewsAddToHotlist(article_id) {

    console.log(article_id);
    var hotlist = getCookie('uloop_news_hotlist');
    try {
        hotlist = JSON.parse(hotlist);
    } catch (e) {
        hotlist = [];
    }

    if (hotlist.indexOf(article_id) !== -1) {
        return;
    }
    hotlist.reverse();
    hotlist.push(article_id);
    hotlist.reverse();
    setCookie('uloop_news_hotlist', JSON.stringify(hotlist), {expires: 60 * 60 * 24 * 90, path: '/'});
}

function USATNewsRemoveFromHotlist(article_id) {
    var hotlist = getCookie('uloop_news_hotlist');
    hotlist = JSON.parse(hotlist);
    if (!hotlist) {
        hotlist = [];
    }
    var index = hotlist.indexOf(article_id);
    hotlist.splice(index, 1);
    setCookie('uloop_news_hotlist', hotlist, {expires: 60 * 60 * 24 * 90, path: '/'});
}

function USATNewsGetHotlist() {
    var hotlist = getCookie('uloop_news_hotlist');
    hotlist = JSON.parse(hotlist);
    if (!hotlist) {
        hotlist = [];
    }
    return hotlist;
}

function WPAttachKeepAliveTimer() {
    if (window.WPKeepAliveTimer == 0) {
        window.WPKeepAliveTimer = setInterval('WPKeepAliveTimerCallback();', 30 * 1000);
    }
}

function WPKeepAliveTimerCallback() {
    var unique = Math.round(Math.random() * 1000);
    var url = plugin_data.uloop_base_domain + '/ajaxapi.php?keep_alive_process=1&' + AJAXSessionParam + '&unique=' + unique;
    dhtmlCampusAveLoadScript(url);
}


function getCookie(name) {
    var matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options) {
    options = options || {};

    var expires = options.expires;

    if (typeof expires == "number" && expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    var updatedCookie = name + "=" + value;

    for (var propName in options) {
        updatedCookie += "; " + propName;
        var propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    document.cookie = updatedCookie;
}

function generate_session_id() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
}

function isInViewport(element) {
    var rect = element.getBoundingClientRect();
    var html = document.documentElement;
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || html.clientHeight) &&
        rect.right <= (window.innerWidth || html.clientWidth)
    );
}

function elementInViewport(el) {
    if(typeof(el) == 'undefined'){
        return;
    }

    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
    }

    return (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        (top + height) <= (window.pageYOffset + window.innerHeight) &&
        (left + width) <= (window.pageXOffset + window.innerWidth)
    );
}

function show_preloader() {
    jQuery("#pages-scroll-indicator .listings-preloader").css('display', '');
}


function hide_preloader() {
    jQuery("#pages-scroll-indicator .listings-preloader").css('display', 'none');
}

function index_show_preloader() {
    jQuery("#index-scroll-indicator .listings-preloader").css('display', '');
}


function index_hide_preloader() {
    jQuery("#index-scroll-indicator .listings-preloader").css('display', 'none');
}


function load_next_page() {
    if (!window.uloop_can_load_next_page)
        return;

    window.uloop_current_page++;
    var page_url = window.location.href;

    if (page_url.indexOf('#') > 0) {
        page_url = page_url.substring(0, page_url.indexOf('#'));
    }

    var url_parts = page_url.split('?');

    var url_to_load = url_parts[0];

    if (url_to_load.substr(url_to_load.length - 1) == '/') {
        url_to_load += 'page/' + window.uloop_current_page;
    } else {
        url_to_load += '/page/' + window.uloop_current_page;
    }

    if (url_parts[1]) {
        url_to_load += '?' + url_parts[1] + '&ajax_request=1&wp_ajax_request=1';
        //}
    } else {
        url_to_load += '?ajax_request=1&wp_ajax_request=1';
    }

    window.uloop_can_load_next_page = false;

    show_preloader();
    jQuery.get(url_to_load, function (data) {

        hide_preloader();

        jQuery('#pages-scroll-indicator').before(data);

        jQuery('.listing-item .wrap-image img, .detail_main_image img').error(function () {
            jQuery(this).attr('src', window.imagesDomain + '/img/no-image-140-140.jpg');
        });

        jQuery('.textbook_detail .tb-d-img img').each(function () {
            if (this.complete && ( this.naturalHeight === 0 || this.naturalWidth === 0 )) {
                errorTextBookCover(this);
            }
            jQuery(this).attr('onerror', 'errorTextBookCover(this);');
        });
        jQuery('.textbook .tb-img img').each(function () {
            if (this.complete && ( this.naturalHeight === 0 || this.naturalWidth === 0 )) {
                errorTextBookCover(this);
            }
            jQuery(this).attr('onerror', 'errorTextBookCover(this);');
        });

        if (data.length > 0) {
            window.uloop_can_load_next_page = true;

        }

        window.CodeAdapter.processExternalLink();

        init_hotlist();

    });
}

/*
 *
 * Crossdomain requests
 *
 */
crossDomainRequest.init = function (session_id) {
    crossDomainRequest.session_name = 'PHPSESSID';
    crossDomainRequest.session_id = session_id;
    crossDomainRequest.requests = [];

    crossDomainRequest.response = function (request_id, response_data) {
        window.crossDomainResponse = '';

        var request = crossDomainRequest.requests[request_id];
        // console.log(request);
        if (typeof(response_data) !== 'undefined') {

            request.response_data = response_data;
        }
        request.request_parts_processed++;

        if ((request.request_parts_count - 1 ) === request.request_parts_processed) {
            request.sendDataFinishRequest();
        }

        if (request.request_parts_processed === request.request_parts_count) {

            if (typeof(request.response_data) !== 'undefined' && typeof(request.response_data.data) !== undefined) {
                var data = request.response_data.data;

                if (request.data_type === 'json') {
                    data = JSON.parse(data);
                }
            }
            request.success_callback(data);
            request.clear();

            window.crossDomainResponse = data;
        }
    };
};
function crossDomainRequest() {

    this.https_only = true;
    this.finish_request_part;
    this.request_id;
    this.success_callback = function () {
    };
    this.url;
    this.request_parts_processed = 0;
    this.request_parts_count = 0;
    this.script_tags = [];
    this.data_type; // 'html' or 'json'
    this._this = this;
    this.response_data;

    this.request = function (options) {
        this.url = options.url;


        if (window.location.host === "www.universityparent.local" || window.location.host === "college.lawrence.com" || window.location.host === "collegeparentcentral.loc") {

            this.url = this.url.replace(".uloop.com", ".uloop.loc");

            if (this.url.indexOf("https://") === -1 && this.url.indexOf("http://") === -1) {
                this.url = 'http://' + this.url;
            } else if (this.url.indexOf("https://") === 0) {
                this.url = this.url.replace("https://", "http://");
            }
        }
        else if (this.https_only) {

            if (this.url.indexOf("https://") === -1 && this.url.indexOf("http://") === -1) {
                this.url = 'https://' + this.url;
            } else if (this.url.indexOf("http://") === 0) {
                this.url = this.url.replace("http://", "https://");
            }
        }
        this.url = this.url.replace(/([^:]\/)\/+/g, "$1");

        this.request_id = 'req' + Date.now() + (Math.floor(Math.random() * 89000) + 10000);
        crossDomainRequest.requests[this.request_id] = this;

        if (typeof(options.dataType) !== 'undefined') {
            this.data_type = options.dataType;
        } else {
            this.data_type = 'html';
        }

        if (typeof(options.success) !== 'undefined') {
            this.success_callback = options.success;
        }
        var data_string = '';
        if (typeof options.data === 'object') {
            data_string = this.http_build_query(options.data);
        } else if (typeof options.data === 'string') {
            data_string = options.data;
        } else {
            data_string = '';
        }

        if (document.referrer) {
            if (data_string) {
                data_string += '&HR=' + document.referrer;
            } else {
                data_string += 'HR=' + document.referrer;
            }
        }

        if (data_string) {
            data_string += '&' + window.CodeAdapter.plugin_param;
        } else {
            data_string += window.CodeAdapter.plugin_param;
        }


        var encoded_data = encodeURIComponent(data_string);
        var data_parts = this.splitRequest(encoded_data, 2000);//1850

        var session_param = '';
        if (typeof(crossDomainRequest.session_id) !== 'undefined') {
            session_param = crossDomainRequest.session_name + '=' + crossDomainRequest.session_id;
        }

        this.request_parts_count = data_parts.length;
        for (var key in data_parts) {
            var req_type = '';
            var req_part = false;
            if (key == (data_parts.length - 1 )) {
                req_type = 'RID=' + this.request_id + '&RF=' + (parseInt(key) + 1);
            }
            else {
                req_type = 'RID=' + this.request_id + '&RP=' + (parseInt(key) + 1);
                req_part = true;
            }

            var request_part = 'data=' + data_parts[key] + '&' + req_type + (session_param ? '&' + session_param : '') + '&' + window.CodeAdapter.plugin_param;
//console.log(request_part);
            if (req_part) {
                this.sendDataPartRequest(request_part);
            }
            else {
                this.finish_request_part = request_part;
                if (this.request_parts_count === 1) {
                    this.sendDataFinishRequest();
                }
            }
        }

    };


    this.http_build_query = function (formdata) {	// Generate URL-encoded query string

        var key, use_val, use_key, i = 0, tmp_arr = [];

        arg_separator = '&';

        for (key in formdata) {
            use_key = escape(key);
            if (typeof formdata[key] === "undefined" || formdata[key] === null) {
                use_val = '';
            }
            else {
                use_val = escape((formdata[key].toString()));
                use_val = use_val.replace(/%20/g, '+');
            }

            tmp_arr[i] = use_key + '=' + use_val;
            i++;
        }

        return tmp_arr.join(arg_separator);
    };


    this.splitRequest = function (string, split_length) {

        var parts = string.split('%');
        var chunks = [];

        var chunk = '';
        for (var i = 0; i < parts.length; i++) {

            chunk += i === 0 ? parts[i] : '%' + parts[i];

            if (typeof(parts[i + 1]) !== 'undefined' && ( chunk.length + parts[i + 1].length + 1 ) > split_length) {
                chunks.push(chunk);
                chunk = '';
            }
        }
        chunks.push(chunk);

        return chunks;
    };

    this.sendDataPartRequest = function (request_part) {
        var e = document.createElement("script");
        e.src = this.url + '?' + request_part;
        e.type = "text/javascript";
        e.setAttribute('async', 'true');
        document.getElementsByTagName("head")[0].appendChild(e);
        this.script_tags.push(e);
    };

    this.sendDataFinishRequest = function () {
        this.sendDataPartRequest(this.finish_request_part);
    };

    this.clear = function () {
        for (var key in this.script_tags) {
            var tag_to_remove = this.script_tags[key];
            tag_to_remove.parentNode.removeChild(tag_to_remove);
        }
    };

}

var CDR = function () {
    return new crossDomainRequest();
};

function trackWidgetViewUloop(widget_id, widget_title, page_type) {
    var widgetTitle = widget_title.toLowerCase(),
        params = {};
    if (widgetTitle.indexOf('recent') !== -1 && widgetTitle.indexOf('classifieds') !== -1) {
        params.widget_type = 'Recent Classifieds';
    }
    else if (widgetTitle.indexOf('study abroad') !== -1) {
        params.widget_type = 'Study Abroad';
    }
    else if (widgetTitle.indexOf('test prep') !== -1) {
        params.widget_type = 'Test Prep';
    }
    else if (widgetTitle.indexOf('online') !== -1 && widgetTitle.indexOf('tutors') !== -1) {
        params.widget_type = 'Online Tutors';
    }
    else if (widgetTitle.indexOf('job') !== -1) {
        params.widget_type = 'Jobs';
    } else if (widgetTitle.indexOf('Housing') !== -1) {
        params.widget_type = 'Housing';
    } else if (widgetTitle.indexOf('Scholarships') !== -1) {
        params.widget_type = 'Scholarships';
    } else if (widgetTitle.indexOf('top') !== -1 && widgetTitle.indexOf('news') !== -1) {
        params.widget_type = 'Top News';
    }

    params.url = window.location.href;
    params.page_type = page_type;
    params.widget_id = widget_id;


    window.CodeAdapter.ajaxRequest({
        url: "ajax_content.php",
        data: 'action=tracking_widget_views' + ( params ? '&' + jQ.param(params) : '' ),
        dataType: "json",
        type: "POST",
        cache: "false",
        success: function (data) {
        }
    });

    let widgets = document.querySelectorAll('#right_content .wrap_b_side');
    for (let widget of widgets) {
        const widgetTitles = widget.querySelectorAll('h3, .h3');
        for (let widgetTitle of widgetTitles) {
            let widgetTitleText = widgetTitle.innerText;
            if (widgetTitleText.indexOf(widget_title) !== -1) {
                const widgetLinks = widget.querySelectorAll('a');
                for (let widgetLink of widgetLinks) {
                    widgetLink.setAttribute('wi', widget_id);
                }
            }
        }
    }
    // var widget_obj = jQ('#right_content h3:contains("' + widget_title + '"), #right_content .h3:contains("' + widget_title + '")').parents('.wrap_b_side');
    // jQ('a', widget_obj).attr('wi', widget_id);
}

function trackUloopEvent(ei, fp, tp, ci, pb, chi, sbi, wi) {
//          console.log(ei+'-'+fp+'-'+tp+'-'+ci+'-'+pb+'-'+chi);
    var domain_id = window.DOMAIN_ID;

    var row = {
        ei: ei,
        fp: encodeURIComponent(fp),
        tp: encodeURIComponent(tp),
        ci: ci,
        pb: pb,
        chi: chi,
        sbi: sbi,
        di: ~~domain_id,
        wi: wi
    };
    var rowArray = [];
    if (getCookie('uloop_widget_clicks') !== undefined) {
        rowArray = JSON.parse(getCookie("uloop_widget_clicks"));
    }
    rowArray.push(row);
    setCookie('uloop_widget_clicks', JSON.stringify(rowArray), {expires: 60 * 60, path: '/'});
    TrackClick();
}


function postClassifiedsButton(link, post_type) {
    if (!window.CurrentUser.getId()) {
        var href = link.getAttribute('href');
        if (typeof(href) == 'undefined' || href == null || !href.length || href == '#') {
            href = '/' + plugin_data.uloop_university_slug + '/post-listing';
        }

        showLoginDialog();

        if (post_type && post_type !== 'default') {
            jQuery('.login-post-buttons').hide();
            jQuery('.login-post-buttons.' + post_type).show();

            jQuery('.login-sign-up-link').hide();
            jQuery('.login-sign-up-link.' + post_type).show();
        }

        jQuery('#globalLoginRedirect').val(href);
        return false;
    } else {
        return true;
    }
}

function showLoginMenu() {

    if (window.CodeAdapter.project_type !== 'wordpress_plugin') {
        return;
    }

    jQuery('.top-login-container .login_menu_container').remove();
//	jQuery('.heading .login_menu_container').remove();

    var sign_up_link = '';
    if (plugin_data.uloop_university_id > 0) {
        sign_up_link = '/' + plugin_data.uloop_university_slug + '/student-sign-up';
    } else {
        sign_up_link = '/student/student-sign-up';
    }
//	jQuery('.header-social-links').append('<div class="login_menu_container">\
//								<ul id="login_menu">\
//								<li><a href="'+sign_up_link+'">Sign Up</a></li>\
//								<li><a href="#" onclick="showLoginDialog(); return false;">Log In</a></li>\
//								</ul>\
//								');
    jQuery('.top-login-container').append('<div class="login_menu_container">\
								<ul id="login_menu">\
								<li><a href="#" onclick="showLoginDialog(); return false;">Log In</a></li>\
								</ul>\
								');
//	jQuery('.header-social-links').append('<div class="login_menu_container">\
//								<ul id="login_menu">\
//								<li><a href="#" onclick="showLoginDialog(); return false;">Sign Up</a></li>\
//								<li><a href="#" onclick="showLoginDialog(); return false;">Log In</a></li>\
//								</ul>\
//								');
//	jQuery('.heading').append('<div class="login_menu_container">\
//								<a href="/'+plugin_data.uloop_university_slug+'/post-listing" class="orange_button_h_36 main_page_orange_button" onclick="return postClassifiedsButton(this);">Post Classifieds</a>\
//								');
}
function showLoggedUserMenu() {

    if (window.CodeAdapter.project_type !== 'wordpress_plugin') {
        return;
    }


    if (getCookie('logged_user_id') > 0) {

        var my_account_link = '';
        if (plugin_data.uloop_university_id > 0) {
            my_account_link = '/' + plugin_data.uloop_university_slug + '/profile';
        } else {
            my_account_link = '/student/profile';
        }

        jQuery('.top-login-container .login_menu_container').remove();
        //jQuery('.heading .login_menu_container').remove();

        jQuery('.top-login-container').append('<div class="login_menu_container">\
									<ul id="login_menu">\
                                                                        <li><a href="' + my_account_link + '">My Account</a></li>\
									<li><a href="#" onclick="return Logout();">Logout</a></li>\
									</ul>\
									</div>');

//		jQuery('.heading').append('<div class="login_menu_container">\
//									<a href="/'+plugin_data.uloop_university_slug+'/post-listing" class="orange_button_h_36 main_page_orange_button" onclick="return postClassifiedsButton(this);">Post Classifieds</a>\
//									</div>');
    }

}

function WP_User_Logged_In_Callback(user_data, reload) {
    if (user_data.user_id) {
        setCookie('login_status', 1, {expires: 0, path: '/'});
        setCookie('logged_user_id', user_data.user_id, {expires: 0, path: '/'});
        setCookie('logged_user_first_name', user_data.first_name, {expires: 0, path: '/'});
        setCookie('logged_user_last_name', user_data.last_name, {expires: 0, path: '/'});
        setCookie('logged_user_email', user_data.email, {expires: 0, path: '/'});
        setCookie('logged_user_can_post_free_ads', user_data.can_post_free_ads, {expires: 0, path: '/'});
        setCookie('logged_user_is_admin', user_data.is_admin, {expires: 0, path: '/'});

        setCookie('hotlist_sync', 1, {expires: 60 * 60, path: '/'});

        if (reload === 'reload') {
            location.reload();
        }
    }
}

function Campus_OpenLink(page) {
    location.href = page;
    return;
    var sessionParams = AJAXSessionParam;

    var unique = Math.round(Math.random() * 1000);
    var BaseURL = AJAXBaseURL;
    if (page.indexOf('?') >= 0)
        page = page + '&';
    else
        page = page + '?';
    var link = page;
    if (page.indexOf('http') < 0)
        link = BaseURL + '/' + link;
    location.href = page;
}

function shorten(text, maxLength) {

}

function ChangePageViewMode(view_mode){
    var view_mode = view_mode || false;

    if(!view_mode || view_mode == window.uloop_current_view_mode){
        return;
    }

    var page_url = window.location.href.replace(/page=[\d]+/g,'');

    if (page_url.indexOf('#') > 0) {
        page_url = page_url.substring(0, page_url.indexOf('#'));
    }

    var url_parts = page_url.split('?');

    var url_to_load = url_parts[0];

    if (url_parts[1]) {
        url_to_load += '?' + url_parts[1];
    }

    window.index_uloop_current_page = 1;
    window.uloop_current_view_mode = view_mode;
    setCookie('uloop-view-mode', view_mode, {expires: 60 * 60 * 1, path: '/'});

    UpdatePageContentPath(url_to_load, true, 'ChangePageViewModeCallback');
}

function ChangePageViewModeCallback(){
    if(jQ('.post-gallery').length){
        jQ(document).trigger('GalleryViewModeUpdatedEvent');
    }

    if(jQ('#index-scroll-indicator').get(0)){
        jQ(document).trigger('FindScrollIndicatorEvent');
    }

    processUloopExternalLinks();
    CheckFavorite();
}

function index_load_next_page() {

    console.log('load next');
    if (!window.index_uloop_can_load_next_page)
        return;

    window.index_uloop_current_page++;
    var page_url = window.location.href;

    if (page_url.indexOf('#') > 0) {
        page_url = page_url.substring(0, page_url.indexOf('#'));
    }

    var url_parts = page_url.split('?');

    var url_to_load = url_parts[0];

    if (url_parts[1]) {
        url_to_load += '?' + url_parts[1] + '&ajax_content_request=1&page=' + window.index_uloop_current_page;
    } else {
        url_to_load += '?&ajax_content_request=1&page=' + window.index_uloop_current_page;
    }

    window.index_uloop_can_load_next_page = false;

    index_show_preloader();
    jQuery.get(url_to_load, function (data) {

        index_hide_preloader();

        jQuery('.content-container').append(data);
        // jQuery('#index-scroll-indicator').before(data);

        if (data.length > 0) {
            window.index_uloop_can_load_next_page = true;

        }

        if(jQ('.post-gallery').length){
            jQ(document).trigger('GalleryViewModeUpdatedEvent');
        }

        processUloopExternalLinks();
        window.CodeAdapter.processExternalLink();

        CheckFavoriteIndex();

        // processImagesScale();

    });
}

function the_loop_load_next_page() {

    if (jQuery('.info-container.no-messages-area').length) { //last message
        return;
    }

    if (!window.index_uloop_can_load_next_page)
        return;

    window.index_uloop_current_page++;
    var page_url = window.location.href;

    if (page_url.indexOf('#') > 0) {
        page_url = page_url.substring(0, page_url.indexOf('#'));
    }

    var url_parts = page_url.split('?');

    var url_to_load = url_parts[0];

    if (url_parts[1]) {
        url_to_load += '?' + url_parts[1] + '&ajax=1&page=' + window.index_uloop_current_page;
    } else {
        url_to_load += '?&ajax=1&page=' + window.index_uloop_current_page;
    }

    window.index_uloop_can_load_next_page = false;

    index_show_preloader();
    jQuery.get(url_to_load, function (data) {

        index_hide_preloader();

        jQuery('.group-messages-list.the-loop').append(data);

        if (data.length > 0) {
            window.index_uloop_can_load_next_page = true;
        }

        processUloopExternalLinks();
        window.CodeAdapter.processExternalLink();

        CheckFavoriteIndex();

        // processImagesScale();

    });
}

jQ.fn.selectToBoxlist = function () {
    var select_elems = this;

    for (var i = 0; i < select_elems.length; i++) {
        var select_elem = select_elems[i];
        selectToBoxlistReplace(select_elem);
    }

    function selectToBoxlistReplace(select_elem) {
        var options = jQ('option', select_elem),
            box_wrap = jQ(select_elem).parents('.list-box-wrap'),
            ul_html = ''

        jQ(select_elem).css({'display': 'none'});
        if(!jQ(box_wrap).length) {
            jQ(select_elem).wrap("<div class=\"list-box-wrap\"></div>");
        } else {
            jQ('ul.list-box', box_wrap).remove();
        }

        if (options.length) {
            ul_html += '<ul class="list-box">';
            for (var j = 0; j < options.length; j++) {
                if (typeof(jQ(options[j]).attr('data')) !== 'undefined' && jQ(options[j]).attr('data') === 'title') {
                    if (jQ(options[j]).is(':selected')){
                        jQ(options[j+1]).prop('selected', true).change();
                    }
                    continue;
                }

                jQ(options[j]).attr('data-index', jQ(options[j]).index());
                var option_content = '';
                if (typeof(jQ(options[j]).attr('data-icon')) != 'undefined') {
                    option_content += '<span class="' + jQ(options[j]).attr('data-icon') + '"></span>';
                }
                option_content += jQ(options[j]).text();
                ul_html += '<li ' +
                    'data-original-index="' + jQ(options[j]).index() + '"' +
                    ( jQ(options[j]).is(':selected') ? 'data-selected="true"' : '') +
                    '>' + option_content + '</li>';
            }
            ul_html += '</ul>';

            jQ(select_elem).after(ul_html);
        }
    }

    jQ('select.select-to-boxlist').on("CustomEventDOMSelectModified", function(e){
        selectToBoxlistReplace(this);
    });

    jQ('body').on('click', 'ul.list-box li', function () {
        var elem = this,
            li_index = jQ(elem).attr('data-original-index'),
            wrap_elem = jQ(elem).parents('.list-box-wrap'),
            select_origin = jQ('select', wrap_elem),
            select_index = jQ('option:selected', select_origin).attr('data-index');

        if (select_index != li_index) {
            jQ('option[data-index="' + li_index + '"]', select_origin).prop("selected", true).change();
        }
    });

    jQ('.list-box-wrap select').change(function () {
        var elem = this,
            index = jQ('option:selected', elem).attr('data-index'),
            wrap_elem = jQ(elem).parents('.list-box-wrap'),
            ul = jQ('ul.list-box', wrap_elem);

        jQ('ul.list-box li', wrap_elem).removeAttr('data-selected');
        jQ('li[data-original-index="' + index + '"]', ul).attr('data-selected', 'true');
    });
}

function resizeSearchBar() {
    if (window.innerWidth <= 560) {
        jQ('#header .login_search').append(jQ('#search_form'));
        jQ('.login_bar_wrap').width(jQ('#login_menu').width() + jQ('#login_menu').width('.login_bar_wrap .hotlist_link') + 2);
    } else {
        jQ('#header .login_search').append(jQ('.login_bar_wrap'));
    }

    var search_input = jQ('#header-bar #search_form .search-autocomplete + .search_input');
    if(window.innerWidth <= 560 && jQ(search_input).attr('placeholder') != jQ(search_input).attr('mobile-placeholder')){
        jQ(search_input).attr('placeholder', jQ(search_input).attr('mobile-placeholder'));
    } else if(window.innerWidth > 560 && jQ(search_input).attr('placeholder') != jQ(search_input).attr('desktop-placeholder')){
        jQ(search_input).attr('placeholder', jQ(search_input).attr('desktop-placeholder'));
    }
}

function submitSignUpToday(event, _this){
    event.preventDefault();
    event.stopPropagation();

    var form = jQuery(_this);

    var validate = Validate_Data(jQ('input, select, textarea, checkbox', form), false);

    if (!validate) {
        return false;
    }

    var form_identification = jQ('input[name="identification"]', form).val();

    if (window.CodeAdapter.project_type === 'wordpress_plugin' && form_identification == 'scholarships_sign_up') {
        openBlankWindow();
    }

    window.CodeAdapter.ajaxRequest({
        url: "index.php",
        data: jQuery(form).serialize(),
        async: false,
        dataType: "html",
        type: "POST",
        success: function (data) {
            if (data) {
                var data = JSON.parse(data);
            }

            if (typeof(data.trigger_error_label) !== 'undefined') {
                if (window.CodeAdapter.project_type === 'wordpress_plugin') {
                    closeBlankWindow();
                }

                var error_elem = jQ('[id="' + data.trigger_error_label.replace('-error', '') + '"][disabled!="disabled"]', form).get(0);
                var error_label = jQ('[id="' + data.trigger_error_label + '"][disabled!="disabled"]', form).get(0);
                if (typeof(data.error) !== 'undefined') {
                    SetErrorText(error_label, data.error, false);

                    if (typeof(error_elem) !== 'undefined') {
                        addCustomEventHandle(error_elem, error_label, 'keydown', false);
                        addCustomEventHandle(error_elem, error_label, 'change', false);
                    }
                }
                BlinkElem(error_label);
            } else if (data.response == 'OK') {

                if(form_identification == 'AnswersLogIn' || form_identification == 'QuestionLogInPopUp' || form_identification == 'QuestionLogIn' || form_identification == 'AnswerUpVoteLogIn' || form_identification == 'AnswerDownVoteLogIn' || form_identification == 'AnswerCommentLogIn' || form_identification == 'QuestionCommentLogIn' || form_identification == 'QuestionDetailAnswerLogIn' || form_identification == "QuestionFollowLogIn"){
                    var l_id = jQ(form).find('input[name="listing_id"]').val();
                    if(l_id) {
                        if(form_identification == 'QuestionLogInPopUp'){
                            setCookie('show-question-popup', l_id, {expires: 60 * 60 * 1, path: '/'});
                        } else if(form_identification == 'AnswersLogIn') {
                            setCookie('show-answer-popup', l_id, {expires: 60 * 60 * 1, path: '/'});
                        } else if(form_identification == 'AnswerUpVoteLogIn') {
                            setCookie('answer_up_vote', l_id, {expires: 60 * 60 * 1, path: '/'});
                        } else if(form_identification == 'AnswerDownVoteLogIn') {
                            setCookie('answer_down_vote', l_id, {expires: 60 * 60 * 1, path: '/'});
                        } else if(form_identification == 'AnswerCommentLogIn') {
                            setCookie('answer_comment', l_id, {expires: 60 * 60 * 1, path: '/'});
                        } else if(form_identification == 'QuestionCommentLogIn') {
                            setCookie('question_comment', l_id, {expires: 60 * 60 * 1, path: '/'});
                        } else if(form_identification == 'QuestionDetailAnswerLogIn') {
                            setCookie('show_answer_form', l_id, {expires: 60 * 60 * 1, path: '/'});
                        } else if(form_identification == 'QuestionFollowLogIn') {
                            setCookie('question_follow_form', l_id, {expires: 60 * 60 * 1, path: '/'});
                        }
                    }
                }

                if (window.CodeAdapter.project_type === 'wordpress_plugin') {
                    if (WP_User_Logged_In_Callback) {
                        WP_User_Logged_In_Callback({
                                user_id: data.user_id,
                                first_name: data.first_name,
                                last_name: data.last_name,
                                email: data.email,
                                can_post_free_ads: data.can_post_free_ads,
                                is_admin: data.is_admin,
                            },
                            'without_reload');
                    }

                    window.sign_up_today_popup_obj.instance.popup.close();
                    if (jQuery(form).find('input[name="view_external_link"]').val()) {
                        LocationlankWindow(jQuery(form).find('input[name="view_external_link"]').val(), 'WindowReload');
                    }
                    sendWelcomeEmailWithPassword();
                } else {
                    setCookie('login_status', 1, {expires: 0, path: '/'});
                    if (jQuery(form).find('input[name="view_external_link"]').val()) {
                        window.open(jQuery(form).find('input[name="view_external_link"]').val(), '_blank');
                    }

                    window.sign_up_today_popup_obj.instance.popup.close();
                    sendWelcomeEmailWithPassword();

                    if (form_identification == 'QuestionLogIn' || form_identification == 'QuestionLogInPopUp' || form_identification == 'AnswersLogIn' || form_identification == 'AnswerUpVoteLogIn' || form_identification == 'AnswerDownVoteLogIn' || form_identification == 'AnswerCommentLogIn' || form_identification == 'QuestionDetailAnswerLogIn' || form_identification == 'QuestionFollowLogIn') {
                        requirejs(['popup/notification'], function (msg) {
                            msg.instance.open('Success, you are now a registered Uloop user and will receive your account password by email');
                        });

                        setTimeout(function () {
                            window.location.reload();
                        }, 4000);
                    } else {
                        window.location.reload();
                    }


                }

            } else {
                if (window.CodeAdapter.project_type === 'wordpress_plugin') {
                    closeBlankWindow();
                }
                requirejs(['popup/notification'], function (msg) {
                    msg.instance.open('Failed to send form!');
                });
            }
        }
    });
}

function showScholarsipsSignUpPopup(link, options) {

    //if(getCookie('scholarships_sign_up_submitted') !== undefined && getCookie('scholarships_sign_up_submitted')) {
    if (getCookie('login_status') !== undefined && getCookie('login_status') && getCookie('login_status') == 1) {
        // if(window.CodeAdapter.project_type === 'wordpress_plugin') {
        // 	window.location = jQuery(link).attr('href');
        // } else {
        // 	window.open(jQuery(link).attr('href'), '_blank');
        // }
        return true;
    } else {
        var options = options || {};
        options.identification = 'scholarships_sign_up';
        options.callback = 'scholarshipsLogIn';
        options.external_link = jQuery(link).attr('href');

        requirejs(['popup/signUpToday'], function (popup) {
            window.sign_up_today_popup_obj = popup;
            window.sign_up_today_popup_obj.instance.open(options);
        });
        return false;
    }
}


function showQAndALoginPopup(options) {
    if (getCookie('login_status') !== undefined && getCookie('login_status') && getCookie('login_status') == 1) {
        return true;
    } else {
        var options = options || {};
        requirejs(['popup/signUpToday'], function (popup) {
            requirejs(['popup/signUpToday'], function (popup) {
                window.sign_up_today_popup_obj = popup;
                window.sign_up_today_popup_obj.instance.open(options);
            });
        });
        return false;
    }
}

function Get_Input_Value(id) {
    var elem = document.getElementById(id);

    if (elem.getAttribute('default-value') && elem.value == elem.getAttribute('default-value')) {
        return '';
    }
    else {
        return elem.value;
    }
}

function Clear_Form_Error(id) {
    if (document.getElementById(id + '-error')) {
        document.getElementById(id + '-error').innerHTML = '';

        document.getElementById(id + '-error').style.visibility = 'hidden';
    }
}

function onlyNumber(event) {
    //backspace, delete, tab Рё escape
    if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 ||
        // Ctrl+A
        (event.keyCode == 65 && event.ctrlKey === true) ||
        // home, end, РІР»РµРІРѕ, РІРїСЂР°РІРѕ
        (event.keyCode >= 35 && event.keyCode <= 39)) {
        return;
    }
    else {
        if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105 )) {
            event.preventDefault();
        }
    }
}


function getPageScroll() {
    var left = 0;
    var top = 0;
    if (window.pageYOffset || document.documentElement.scrollTop) {
        top = window.pageYOffset || document.documentElement.scrollTop;
        left = window.pageXOffset || document.documentElement.scrollLeft;
    } else {
        var html = document.documentElement;
        var body = document.body;

        var scrollTop = html.scrollTop || body && body.scrollTop || 0;
        var scrollLeft = html.scrollLeft || body && body.scrollLeft || 0;
        top = scrollTop - html.clientTop;
        left = scrollLeft - html.clientLeft;
    }
    return {
        top: top,
        left: left
    };
}

function isValidEmailAddress(emailAddress) {
    var emailAddress = CMPS_Trim(emailAddress);
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,10}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(emailAddress);
}

function validationEmail(email, emailERROR) {
    if (!('placeholder' in document.createElement('input'))) {
        if (email.getAttribute('placeholder') && email.value == email.getAttribute('placeholder')) {
            email.value = '';
        }
    }

    if (email.value.length === 0 || CMPS_hasHTMLTags(email.value) || !isValidEmailAddress(email.value)) {

        emailERROR.style.color = "red";
        if (email.value.length === 0)
            CMPS_SetErrorText_noneScroll(emailERROR, ' Please enter correct email');
        else if (CMPS_hasHTMLTags(email.value))
            CMPS_SetErrorText_noneScroll(emailERROR, ' Please enter text only');
        else if (!isValidEmailAddress(email.value))
            CMPS_SetErrorText_noneScroll(emailERROR, ' Please enter correct email');
        email.focus();
        email.onkeydown = function () {
            if (emailERROR) {
                emailERROR.style.visibility = 'hidden';
                if (emailERROR.className.indexOf('email-error-for-edu') !== -1) {
                    emailERROR.innerHTML = '';
                }
                this.onkeydown = null;
            }
        }
        return false;
    }
    else return true;
}

function validationInput(input, str_error) {
    if (!('placeholder' in document.createElement('input'))) {
        if (input.getAttribute('placeholder') && input.value == input.getAttribute('placeholder')) {
            input.value = '';
        }
    }

    if (input.value.length === 0 || CMPS_hasHTMLTags(input.value)) {
        input.focus();

        if (input.value.length === 0) {
            CMPS_SetErrorText_noneScroll(str_error, jQ(str_error).text());
        }
        else if (CMPS_hasHTMLTags(input.value))
            CMPS_SetErrorText_noneScroll(str_error, ' Please enter text only');


        input.onkeydown = function () {
            if (str_error) {
                str_error.style.visibility = 'hidden';
                this.onkeydown = null;
            }
        }

        input.onchange = function () {
            if (str_error) {
                str_error.style.visibility = 'hidden';
                this.onkeydown = null;
            }
        }

        return false;
    }
    else return true;
}

function validationShowError(input, str_error) {


    CMPS_SetErrorText_noneScroll(str_error, jQ(str_error).text());

    input.focus();
    input.onkeydown = function () {
        if (str_error) {
            str_error.style.visibility = 'hidden';
            this.onkeydown = null;
        }
    }
    return false;

}

function validationPhone(phone1, phone2, phone3, str_error) {
    if (phone1.value.length < 3 || phone2.value.length < 3 || phone3.value.length < 4 || CMPS_hasHTMLTags(phone1.value) || CMPS_hasHTMLTags(phone2.value) || CMPS_hasHTMLTags(phone3.value)) {
        if (phone1.value.length == 0 || phone2.value.length == 0 || phone3.value.length == 0)
            CMPS_SetErrorText_noneScroll(str_error, jQ(str_error).text());
        else if (CMPS_hasHTMLTags(phone1.value) || CMPS_hasHTMLTags(phone2.value) || CMPS_hasHTMLTags(phone3.value))
            CMPS_SetErrorText_noneScroll(str_error, ' Please enter text only');
        else if (phone1.value.length < 3 || phone2.value.length < 3 || phone3.value.length < 4) {
            CMPS_SetErrorText_noneScroll(str_error, ' Not valid Phone');
        }
        phone1.focus();
        phone1.onkeydown = function () {
            if (str_error) {
                str_error.style.visibility = 'hidden';
                this.onkeydown = null;
            }
        }
        phone2.onkeydown = function () {
            if (str_error) {
                str_error.style.visibility = 'hidden';
                this.onkeydown = null;
            }
        }
        phone3.onkeydown = function () {
            if (str_error) {
                str_error.style.visibility = 'hidden';
                this.onkeydown = null;
            }
        }
        return false;
    }
    else return true;
}

function validatePhoneNumber(number, errorContainer) {
    var number = number || null,
        errorContainer = errorContainer,
        pattern = /^\(?([2-9][0-9]{2})\)?[-. ]?([2-9](?!11)[0-9]{2})[-. ]?([0-9]{4})$/,
        countryCode = /^1\(?([2-9][0-9]{2})\)?[- ]?([2-9](?!11)[0-9]{2})[- ]?([0-9]{3})$/;

    if (!jQ(number).val().match(pattern) && !jQ(number).val().match(countryCode)) {
        CMPS_SetErrorText_noneScroll(errorContainer, jQ(errorContainer).text());
        number.onkeydown = function () {
            if (errorContainer) {
                errorContainer.style.visibility = 'hidden';
                this.onkeydown = null;
            }
        }
        return false;
    }
    return true;
}

function FormatPhone (e,input) {
    evt = e || window.event; /* firefox uses reserved object e for event */
    var pressedkey = evt.which || evt.keyCode;
    var BlockedKeyCodes = new Array(8,27,13,9); //8 is backspace key
    var len = BlockedKeyCodes.length;
    var block = false;
    var str = '';
    for (i=0; i<len; i++){
        str=BlockedKeyCodes[i].toString();
        if (str.indexOf(pressedkey) >=0 ) block=true;
    }
    if (block) return true;

    s = input.value;
    if (s.charAt(0) =='+') return false;
    filteredValues = '"`!@#$%^&*()-_+|~-=\QWERT YUIOP{}ASDFGHJKL:ZXCVBNM<>?qwertyuiop[]asdfghjkl;zxcvbnm,./\\\'';
    var i;
    var returnString = '';

    /* Search through string and append to unfiltered values
       to returnString. */

    for (i = 0; i < s.length; i++) {
        var c = s.charAt(i);

        //11-Digit number format if leading number is 1

        if (s.charAt(0) == 1){
            if ((filteredValues.indexOf(c) == -1) & (returnString.length <  13 )) {
                if (returnString.length==1) returnString +='(';
                if (returnString.length==5) returnString +=')';
                if (returnString.length==6) returnString +=' ';
                if (returnString.length==10) returnString +='-';
                returnString += c;
            }
        }

        //10-digit number format
        else{console.log(returnString)
            if ((filteredValues.indexOf(c) == -1) & (returnString.length <=  13)) {
                if (returnString.length==0) returnString +='(';
                if (returnString.length==4) returnString +=')';
                if (returnString.length==5) returnString +=' ';
                if (returnString.length==9) returnString +='-';
                returnString += c;
            }
        }

    }
    input.value = returnString;

    return false
}

function validationPassws(passw, confirmPassw, str_error) {
    if (passw.value !== confirmPassw.value) {
        //if(passw.value.length<=5) || passw.value.length<=5 || confirmPassw.value.length<=5
        //CMPS_SetErrorText_noneScroll(str_error,' Please enter more than 5 characters');
        if (passw.value !== confirmPassw.value)
            CMPS_SetErrorText_noneScroll(str_error, ' Entered different passwords');
        passw.focus();
        passw.onkeydown = function () {
            if (str_error) {
                str_error.style.visibility = 'hidden';
                this.onkeydown = null;
            }
        }
        confirmPassw.onkeydown = function () {
            if (str_error) {
                str_error.style.visibility = 'hidden';
                this.onkeydown = null;
            }
        }
        return false;
    }
    else return true;
}

function FaceBookLogin(noReload, fromPosring) {
    return;
    var callbackFunction = false;
    if (!noReload)
        noReload = false;
    if (fromPosring)
        callbackFunction = true;

    var redirect = jQ('#globalLoginRedirect').val();

    var Width = ((jQ("body").width() - 420) / 2);
    var unique = Math.round(Math.random() * 1000);
    window.uloopFacebookPopup = window.open(AJAXBaseURL + '/fc.login.php?unique=' + unique + AJAXSessionParam + '&callback_func=' + callbackFunction, 'campusFCPopup', 'top=305,left=' + Width + ',width=600,height=460,resizable=no,menubar=no,scrollbars=no,status=no,location=no');
    window.uloopFacebookPopupWatchDogTimer = setInterval(function () {
        if (window.uloopFacebookPopup == null || window.uloopFacebookPopup.closed) {
            window.clearInterval(window.uloopFacebookPopupWatchDogTimer);

            if (noReload) {
                jQ('#sgn_in_posting_popup .popup_close_button').click();
            } else {
                if (redirect) {
                    if (loginRegisterCallback)
                        loginRegisterCallback('login', redirect, '', '');
                } else {
                    if (window.location.host.indexOf('www.') !== -1) {
                        window.location = 'http://' + window.location.host + window.location.pathname + '?check-sd=1';
                    } else {
                        location.reload();
                    }
                }
            }
        }

        if (window.campusApiHideAllPopups)
            window.campusApiHideAllPopups();

        if (fromPosring) {
            var sessionParams = AJAXSessionParam;
            var unique = Math.round(Math.random() * 1000);
            var URL = AJAXBaseURL + '/ajaxapi.php?update_posting_acount_info=1' + sessionParams + '&unique=' + unique;
            dhtmlCampusAveLoadScript(URL);
        }

    }, 100);
}


function ForgotPasswordForm() {

    if (window.CodeAdapter.project_type === 'wordpress_plugin') {
        var popup_id = "forgot_form";
        popup
        ({
            popup_id: popup_id,
            speed: 100
        });
    } else {
        requirejs(['popup/forgotPassword'], function (forgot) {
            forgot.instance.open();
        });
    }

}

function showNotAStudentPopup() {
    popup
    ({
        popup_id: "not_a_student_popup",
        speed: 100,
        close_button: true
    });
}


function Logout() {

    if (window.CodeAdapter.project_type === 'wordpress_plugin') {
        popup
        ({
            popup_id: "logout_info",
            speed: 100,
            close_button: false
        });
    } else {
        requirejs(['popup/logoutInfo'], function (logout) {
            logout.instance.open();
        });
    }
    window.CodeAdapter.ajaxRequest({
        url: "index.php",
        data: 'action=logout',
        dataType: "html",
        type: "POST",
        success: function (date) {
            setTimeout(function () {
                if (window.CodeAdapter.project_type === 'wordpress_plugin') {
                    jQ(window).off('beforeunload');
                    window.CurrentUser.logoutUser();
                    //showLoginMenu();
                    //hideVisiblePopups(true);
                    if (window.CodeAdapter.page_with_required_login) {
                        window.location = '/' + plugin_data.uloop_university_slug;
                    } else {
                        location.reload();
                    }

                } else {
                    setCookie('login_status', '', {expires: -1, path: '/'});
                    location.reload();
                }

                //window.location = '/';
            }, 200);
        }
    });
    return false;

}

function submitStZip() {
    jQ('#get_zip').remove();
}

function buildStUnit(elem, id, display, maxDate='', closedDays=[], closedDates=[]) {
    var parent = jQ(elem).parents('.storage');
    if (display) {
        jQ('.reserve_form_container form input[name="spaceId"]', parent).val(id);

        if (maxDate) {
            jQ('.reserve_form_container form input[name="start_date"]', parent).attr('max_date', maxDate);
            jQ('.reserve_form_container form input[name="start_date"]', parent).datepicker('option', {minDate: null, maxDate: maxDate});
        }
        if (closedDays || closedDates) {
            var days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
            jQ('.reserve_form_container form input[name="start_date"]', parent).datepicker('option', {
                beforeShowDay: function (date) {
                    var day = days[date.getDay()];
                    var formattedDate = jQ.datepicker.formatDate('mm/dd/yy', date);

                    if (closedDays.indexOf(day) !== -1) {
                        return [false, ''];
                    }
                    if (closedDates.indexOf(formattedDate) !== -1) {
                        return [false, ''];
                    }
                    return [true, ''];
                }
            });
        }
        jQ('.st_unit_size_table', parent).css('display', 'none');
        jQ('#desc_reserv_' + id, parent).css('display', 'block');
        jQ('.hide_block', parent).css('display', 'block');
    }
    else {
        jQ('.st_unit_size_table', parent).css('display', '');
        jQ('#desc_reserv_' + id, parent).css('display', 'none');
        jQ('.hide_block', parent).css('display', 'none');
    }
    return false;
}

var postAvailableDates = [];

function getKnockAppointmentAvailabilityEntrata(dates) {
    var label = '';
    var slide = 1;
    var currentDate = new Date();
    var currentDateFormated = ("0" + (currentDate.getMonth() + 1)).slice(-2) + "/" + ("0" + currentDate.getDate()).slice(-2) + "/" + currentDate.getFullYear();

    dates.forEach(function (acceptableDatetime) {
        var availableDate = new Date(acceptableDatetime.slice(0,19));
        var availableDateFormated = ("0" + (availableDate.getMonth() + 1)).slice(-2) + "/" + ("0" + availableDate.getDate()).slice(-2) + "/" + availableDate.getFullYear();

        label = days[ availableDate.getDay() ] + ' ' + (availableDate.getMonth()+1) + '/' + availableDate.getDate();

        if (availableDateFormated == currentDateFormated) {
            label = 'Today';
        }

        if (jQ('.reply .reply-date-wrap .product-list').length ) {
            jQ('.reply .reply-date-wrap .product-list').each(function (index, elem) {
                var parent = jQ(elem).parents('.reply');
                var timestamp = Date.now() + Math.random();

                jQ(this, parent).append('<li class="product-list__item">' +
                    '<div data-slide="'+slide+'" class="product">' +
                    '<input type="radio" name="date" id="reply-date-'+timestamp+'" value="'+availableDateFormated+'" onclick="fillKnockAppointmentAvailabilityTime(this);">' +
                    '<label for="reply-date-'+timestamp+'" >'+ label +'</label>' +
                    '</div>' +
                    '</li>');

                jQ('[name="reply-date-hidden"]', parent).append('<option value="' + availableDateFormated + '">' + availableDateFormated + '</option>');
            });
        }

        slide++;
    });

    var carousels = document.querySelectorAll('.reply-date-wrap .js-product-carousel');
    [].forEach.call(carousels, function(carousel) {
        carouselize(carousel);
    });

    fillKnockAppointmentAvailabilityTime(jQ('[data-slide="1"] [name="date"]'), true);
}

function getHyLyAppointmentAvailability(communityId, today) {
    // const API_KEY = "Qd2MjxVbhLrUJ6I5OFWoiP1YtaSgTHfk3vBNA4Z7";
    // const endpoint = "https://my.hyly.us/opentours-gql";
    var API_KEY = "yht0XNSWozQmJf3ekP82i4TgFGw1aVCprxuY9q5H";
    var endpoint = "https://my.hy.ly/opentours-gql";

    // console.log(communityId);
    if (!Object.keys(postAvailableDates).length) {
        jQ.ajax({
            url: endpoint,
            contentType: "application/json",
            headers: {
                Authorization: API_KEY
            },
            type: 'POST',
            data: JSON.stringify({
                query: 'query {' +
                    'slots(' +
                    'propertyId: "' + communityId + '",' +
                    'startDate: "' + today + '", ' +
                    'numberOfDays: 10' +
                    ') {' +
                    'datetime \n' +
                    'duration \n' +
                    'isAvailable \n' +
                    '}' +
                    '}',
            }),
            success: function (responce) {
                var slide = 1;
                var currentDate = new Date();
                var currentDateFormated = ("0" + (currentDate.getMonth() + 1)).slice(-2) + "/" + ("0" + currentDate.getDate()).slice(-2) + "/" + currentDate.getFullYear();

                // console.log(responce);

                if(typeof responce.data.slots !== typeof undefined) {
                    responce.data.slots.forEach(function (acceptableDatetime) {
                        if (acceptableDatetime.isAvailable == true) {
                            buildAvailableDatesArr(acceptableDatetime.datetime, slide, currentDateFormated);
                            slide++;
                        }
                    });

                    var carousels = document.querySelectorAll('.reply-date-wrap .js-product-carousel');
                    [].forEach.call(carousels, function(carousel) {
                        carouselize(carousel);
                    });

                    if (window.innerWidth >= 450 || jQ('.category_view').length) {
                        fillKnockAppointmentAvailabilityTime(jQ('[data-slide="1"] [name="date"]'), true);
                    }
                }

            },
            error: function (error) {
                console.log(error);
            }
        })
    }
}

function getKnockAppointmentAvailability (communityId) {
    // const API_KEY = "syndication-appointment-TEST-KEY";
    // const endpoint = "https://stage-syndication.knockrentals.com/community/b9XQN6eMvvrr07zg/available-times";
    var API_KEY = "OgIDW6rlsQ5jrvFm6D6JT9PlE1LT6XWia5ilaYGG";
    var endpoint = "https://syndication.knockrentals.com/community/"+communityId+"/available-times";

    if (!Object.keys(postAvailableDates).length) {
        jQ.ajax({
            url: endpoint,
            headers: {'Content-Type': 'application/json', 'x-api-key': API_KEY},
            type: 'GET',
            success: function (responce) {
                var slide = 1;
                var currentDate = new Date();
                var currentDateFormated = ("0" + (currentDate.getMonth() + 1)).slice(-2) + "/" + ("0" + currentDate.getDate()).slice(-2) + "/" + currentDate.getFullYear();

                if(typeof responce.acceptableTimes !== typeof undefined) {
                    responce.acceptableTimes.forEach(function (acceptableDatetime) {
                        buildAvailableDatesArr(acceptableDatetime, slide, currentDateFormated);
                        slide++;
                    });

                    var carousels = document.querySelectorAll('.reply-date-wrap .js-product-carousel');
                    [].forEach.call(carousels, function(carousel) {
                        carouselize(carousel);
                    });

                    if (window.innerWidth >= 450 || jQ('.category_view').length) {
                        fillKnockAppointmentAvailabilityTime(jQ('[data-slide="1"] [name="date"]'), true);
                    }
                }

            }
        })
    }
}

function buildAvailableDatesArr(acceptableDatetime, slide, currentDateFormated) {
    var availableDate = new Date(acceptableDatetime.slice(0,19));
    var availableDateFormated = ("0" + (availableDate.getMonth() + 1)).slice(-2) + "/" + ("0" + availableDate.getDate()).slice(-2) + "/" + availableDate.getFullYear();

    if (typeof postAvailableDates[availableDateFormated] === typeof undefined) {
        postAvailableDates[availableDateFormated] = [];

        var label = days[ availableDate.getDay() ] + ' ' + (availableDate.getMonth()+1) + '/' + availableDate.getDate();

        if (availableDateFormated == currentDateFormated) {
            label = 'Today';
        }

        if (jQ('.reply .reply-date-wrap .product-list').length ) {
            jQ('.reply .reply-date-wrap .product-list').each(function (index, elem) {

                var parent = jQ(elem).parents('.reply');
                var timestamp = Date.now() + Math.random();

                jQ(this, parent).append('<li class="product-list__item">' +
                    '<div data-slide="'+slide+'" class="product">' +
                    '<input type="radio" name="date" id="reply-date-'+timestamp+'" value="'+availableDateFormated+'" onclick="fillKnockAppointmentAvailabilityTime(this);">' +
                    '<label for="reply-date-'+timestamp+'" >'+ label +'</label>' +
                    '</div>' +
                    '</li>');

                jQ('[name="reply-date-hidden"]', parent).append('<option value="' + availableDateFormated + '">' + availableDateFormated + '</option>');
            });
        }
    }

    if(postAvailableDates[availableDateFormated].indexOf(acceptableDatetime) === -1) {
        postAvailableDates[availableDateFormated].push(acceptableDatetime);
    }
}

function fillKnockAppointmentAvailabilityTime(date_elem) {
    // console.log(jQ(date_elem));
    var date = jQ(date_elem).val();
    var parent = jQ(date_elem).parents('.reply');

    jQ('[name="reply-date-hidden"]', parent).val(date);
    jQ('.product-list__item', parent).removeClass('selected-date');
    jQ(date_elem, parent).parents('.product-list__item').addClass('selected-date');

    if (typeof date == typeof undefined) {
        return;
    }

    if (date.length && typeof postAvailableDates[date] !== typeof undefined ) {

        jQ('[name="reply-datetime-hidden"]').find('option').remove();
        jQ('.reply-time-wrap .product-list').html('');
        jQ('.timeslots-left').text(postAvailableDates[date].length);

        if (postAvailableDates[date].length > 1) {
            jQ('.timeslots-count').text('timeslots');
        } else {
            jQ('.timeslots-count').text('timeslot');
        }

        var slide_datetime = 1;

        postAvailableDates[date].forEach(function (datetime) {
            var availableTime = datetime.split('T')[1].split(':')[0] + ':' + datetime.split('T')[1].split(':')[1];

            jQ('.reply-time-wrap .product-list').each(function (index, elem_li) {
                var parent_elem = jQ(elem_li).parents('.reply');
                var timestamp = Date.now() + Math.random();

                var li_elem = '<li class="product-list__item">' +
                    '<div data-slide="' + slide_datetime + '" class="product">' +
                    '<input type="radio" name="datetime" id="reply-datetime-' + timestamp + '" value="' + datetime + '"  onclick="gotoStep2KnockForm(this);" />' +
                    '<label for="reply-datetime-' + timestamp + '">' + convertFrom24To12Format(availableTime) + '</label>' +
                    '</div>' +
                    '</li>';
                var option_elem = '<option value="' + datetime + '">' + convertFrom24To12Format(availableTime) + '</option>';

                jQ('.reply-time-wrap .product-list',parent_elem).append(li_elem);
                jQ('[name="reply-datetime-hidden"]', parent_elem).append(option_elem);
            });

            slide_datetime++;
        });

        if (window.innerWidth <= 450 && !jQ('.ad_view .reply-time-wrap').is(':visible')) {
            jQ('.ad_view .reply-time-wrap').fadeIn();
        }

        var carousels = document.querySelectorAll('.reply-time-wrap .js-product-carousel');
        [].forEach.call(carousels, function(carousel) {
            carouselize(carousel);
        });

    }
}

function gotoStep2KnockForm(elem) {
    var parent = jQ(elem).parents('.reply');

    //hide Step 1
    jQ('.book-tour-section-1', parent).hide();

    // update Step 2 time
    jQ('.time-section .btn-label', parent).text(jQ('.reply-time-wrap [name="datetime"]:checked+label', parent).text());
    jQ('[name="reply-datetime-hidden"]', parent).val(jQ('.reply-time-wrap [name="datetime"]:checked', parent).val());

    var dateAvailable = new Date(jQ('.reply-time-wrap [name="datetime"]:checked', parent).val());
    jQ('.date-section .btn-label').text(days[ dateAvailable.getDay() ] + ', ' + months[ dateAvailable.getMonth() ] + ' ' + dateAvailable.getDate());

    // show Step 2
    jQ('.book-tour-section-2, .btn-reply, .policy_label', parent).fadeIn();
}

function showDatetimeListStep2(elem) {
    var parent = jQ(elem).parents('.reply');

    if (!jQ('.book-tour-section-2', parent).is(":hidden")) {
        // fillKnockAppointmentAvailabilityTime(jQ('.hasDatepicker', parent));

        jQ('.time-section .btn-label', parent).hide();
        jQ('#reply-datetime-hidden', parent).css({
            'display': 'inline-block',
            'width': 'auto',
            'font-size': '14px !important'
        });
    }
}

function showDateListStep2(elem) {
    var parent = jQ(elem).parents('.reply');

    if (!jQ('.book-tour-section-2', parent).is(":hidden")) {
        jQ('.date-section .btn-label', parent).hide();
        jQ('[name="reply-date-hidden"]', parent).css({
            'display': 'inline-block',
            'width': '80px',
        });
    }
}

function updateBookTourTime(elem) {
    var parent = jQ(elem).parents('.reply');

    jQ('.reply-time-wrap [name="datetime"]:checked', parent).prop("checked", false);
    jQ('.reply-time-wrap [value="' + jQ(elem).val() + '"]', parent).prop("checked", true);

    jQ('.time-section .btn-label', parent).text(convertFrom24To12Format(jQ(elem).val()));

    jQ('#reply-datetime-hidden', parent).hide();
    jQ('.time-section .btn-label', parent).css({'display': 'inline-block'});
}

function updateBookTourDate(elem) {
    var parent = jQ(elem).parents('.reply');

    jQ('.reply-date-wrap [name="date"]:checked', parent).prop("checked", false);
    jQ('.reply-date-wrap [value="' + jQ(elem).val() + '"]', parent).prop("checked", true);

    var dateAvailable = new Date(postAvailableDates[jQ(elem).val()][0]);
    jQ('.date-section .btn-label', parent).text(days[ dateAvailable.getDay() ] + ', ' + months[ dateAvailable.getMonth() ] + ' ' + dateAvailable.getDate());

    jQ('[name="reply-date-hidden"]', parent).hide();
    jQ('.date-section .btn-label', parent).css({'display': 'inline-block'});

    fillKnockAppointmentAvailabilityTime(jQ('.reply-date-wrap [name="date"]:checked', parent));

    showDatetimeListStep2(jQ('.time-section .btn-edit', parent));
}

function clearBookTourForm() {
    jQ('.book-tour-section-2, .btn-reply, .policy_label').hide();
    jQ('.book-tour-section-1 [name="date"]').prop('checked', false);
    jQ('.ad_view .reply-time-wrap').hide();
    jQ('.product-list__item').removeClass('selected-date');
    jQ('.book-tour-section-1'). fadeIn();
}

function carouselize(carousel) {
    var productList = carousel.querySelector('.js-product-list');
    var productListWidth = 0;
    var productListSteps = 0;
    var products = carousel.querySelectorAll('.product');
    var productAmount = 0;
    var productAmountVisible = 3;
    var carouselPrev = carousel.querySelector('.js-carousel-prev');
    var carouselNext = carousel.querySelector('.js-carousel-next');
    var carousel__view = carousel.querySelector('.carousel__view');
    var carousel__items = carousel.querySelector('.product');
    var carouselWidth = carousel__view.offsetWidth;
    var controlWidth = 13;
    var marginTotal = 10;
    var margin = 5;

    var product_width = productWidth();

    [].forEach.call(products, function(product) {
        productAmount++;
        productListWidth += 250;
        productList.style.width = productListWidth+"px";

        product.style.width = product_width + 'px';
    });


    if (productListSteps < productAmount-productAmountVisible) {
        showCarouselNext();
    } else {
        hideCarouselNext();
    }

    carouselNext.onclick = function() {
        if(productListSteps < productAmount-productAmountVisible) {
            showCarouselPrev();
            productListSteps++;
            moveProductList();
        }

        if (productListSteps == productAmount-productAmountVisible) {
            hideCarouselNext();
        }
    }

    carouselPrev.onclick = function() {
        if(productListSteps > 0) {
            showCarouselNext();
            productListSteps--;
            moveProductList();
        }

        if (productListSteps == 0) {
            hideCarouselPrev();
        }
    }

    function productWidth() {

        if (!carouselWidth) {
            carouselWidth = 300;
            productAmountVisible = 4;
            marginTotal = 15;
        }

        if (carousel.querySelector('.js-carousel-prev').offsetWidth) {
            var productWidth =  (carouselWidth - marginTotal - controlWidth*2) / productAmountVisible ;
        } else {
            var productWidth =  (carouselWidth - marginTotal - controlWidth) / productAmountVisible ;
        }

        return productWidth;
    }

    function changeProductWidth() {
        product_width = productWidth();
        [].forEach.call(products, function(product) {
            product.style.width = product_width + 'px';
        });
    }

    function showCarouselPrev() {
        if (carousel.querySelector('.js-carousel-prev').offsetWidth == 0) {
            carousel.querySelector('.js-carousel-prev').style.display = 'table';
            carousel.querySelector('.js-product-list').style.marginLeft = controlWidth + 'px';
            changeProductWidth();
        }
    }

    function hideCarouselPrev() {
        carousel.querySelector('.js-carousel-prev').style.display = 'none';
        carousel.querySelector('.js-product-list').style.marginLeft = '0';
        changeProductWidth();
    }

    function showCarouselNext() {
        if (carousel.querySelector('.js-carousel-next').offsetWidth == 0) {
            carousel.querySelector('.js-carousel-next').style.display = 'table';
        }
    }

    function hideCarouselNext() {
        carousel.querySelector('.js-carousel-next').style.display = 'none';
    }

    function moveProductList() {
        // if ((window.innerWidth <= 1665 && window.innerWidth >= 1024) ||
        //     (window.innerWidth <= 650 && window.innerWidth >= 450)) {
        //     var margin = 3;
        // } else {
        //     var margin = 10;
        // }

        productList.style.transform = "translateX(-"+ (products[productListSteps].offsetWidth + margin) * productListSteps +"px)";
    }
}

function convertFrom24To12Format(time24) {
    var time = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    var sHours = time[0];
    var minutes = time[1];
    var period = +sHours < 12 ? 'AM' : 'PM';
    var hours = +sHours % 12 || 12;

    return hours+':'+minutes+' '+period;
}

/*SUBSCRIBE*/
function Subscribe(this_elem, id_form, search) {
    if(jQ(this_elem).attr('name') != 'subscribe'){
        this_elem = jQ(this_elem).parents("form[name='subscribe']");
    }

    jQ('#ad-info-email-error', this_elem).css({"visibility": "hidden"});

    if (jQ(this_elem).hasClass('v2')) {
        var validate = Validate_Data(jQ('input, select, textarea, checkbox', this_elem), false);
    } else {
        var email = jQ(this_elem).find("input[name='email']").get(0);
        if (typeof(email) == 'undefined') {
            var email = jQ(this_elem).find("input[name='ad-info-email']").get(0);
        }
        var emailERROR = jQ(this_elem).find("#ad-info-email-error").get(0);

        var validate = validationEmail(email, emailERROR);
    }

    if (!validate) {
        return false;
    } else {
        if (id_form == 1) {
            jQ('.follow_sm_preload_sidebar').fadeIn(300);
        }
        else if (id_form == 3) {
            jQ('.follow_sm_preload_bottom').fadeIn(300);
        }
        else {
            jQ('.follow_sm_preload').fadeIn(300);
        }
        var action = jQ(this_elem).find("input[type='submit']").attr('onclick');
        jQ(this_elem).find("input[type='submit']").attr('onclick', '').attr('disabled', true);
        window.CodeAdapter.ajaxRequest({
            url: "index.php",
            data: jQ(this_elem).serialize() + '&id_fotm=' + id_form,
            dataType: "html",
            type: "POST",
            cache: "false",
            success: function (data) {
                jQ(this_elem).find('#ad-info-email-error').html(data).css({
                    "visibility": "visible",
                    "color": "#388908"
                });

                if (id_form == 1) {
                    jQ('.follow_sm_preload_sidebar').fadeOut(300);
                }
                else if (id_form == 3) {
                    jQ('.follow_sm_preload_bottom').fadeOut(300);
                }
                else if (id_form == 5) {
                    jQ('.follow_sm_preload').fadeOut(300);
                    setTimeout(function() {
                        requirejs(['popup/findOutMore'], function (find_out_more_popup) {
                            find_out_more_popup.instance.close();
                        });
                    }, 5000);
                }
                else {
                    jQ('.follow_sm_preload').fadeOut(300);
                }

                jQ(this_elem).find("input[type='submit']").attr('onclick', action).attr('disabled', false);

                if (window.CodeAdapter.project_type == 'wordpress_plugin') {
                    setCookie('uloop_email_subscribed', ~~jQ(this_elem).find("input[name='channel']").val(), {
                        expires: 60 * 60 * 24 * 90,
                        path: '/'
                    });
                }

            },
            error: function () {
                jQ(this_elem).find("input[type='submit']").attr('onclick', action).attr('disabled', false);
            }
        });

        return false;
    }

    return false;
}


/*REPLY BY EMAIL (RESPONSE AND REGISTRATION)*/
//type = 2 - sublet
function ReplyByEmail(this_elem, phone) {

    if (jQ('#reply_conversion').length)
        jQ('#reply_conversion').remove();

    var parent_form = jQ(this_elem).parents(".reply");

    var validate = Validate_Data(jQ('input, select, textarea, checkbox, radio', parent_form), false);

    if (!validate) {
        return false;
    }

    var slideFlag = true;

    if (jQ(this_elem).parents(".super_popup, .sidebar_check_avail").length) {
        slideFlag = false;
    }

    // var firstName = jQ(parent_form).find("#ad-info-first-name").get(0);
    // var firstNameERROR = jQ(parent_form).find("#ad-info-first-name-error").get(0);
    // if (!validationInput(firstName, firstNameERROR))
    //     return false;
    //
    // var lastName = jQ(parent_form).find("#ad-info-last-name").get(0);
    // var lastNameERROR = jQ(parent_form).find("#ad-info-last-name-error").get(0);
    // if (!validationInput(lastName, lastNameERROR))
    //     return false;
    //
    // if (phone) {
    //     var phone1 = jQ(parent_form).find("input[name$='phone1']").get(0);
    //     var phone2 = jQ(parent_form).find("input[name$='phone2']").get(0);
    //     var phone3 = jQ(parent_form).find("input[name$='phone3']").get(0);
    //     var phoneERROR = jQ(parent_form).find("#ad-info-phone-error").get(0);
    //     if (!validationPhone(phone1, phone2, phone3, phoneERROR))
    //         return false;
    // }
    //
    // var email = jQ(parent_form).find("#ad-info-email").get(0);
    // var emailERROR = jQ(parent_form).find("#ad-info-email-error").get(0);
    // if (!validationEmail(email, emailERROR))
    //     return false;
    //
    // if (emailERROR.className.indexOf('email-error-for-edu') !== -1 && !ReplyByEmailWhiteList(email.value)) {
    //
    //     var email_domain = email.value.substring(email.value.indexOf('@') + 1);
    //
    //     var email_domain_parts = email_domain.split('.');
    //
    //     if (email_domain_parts.indexOf('edu') === -1) {
    //         CMPS_SetErrorText_noneScroll(emailERROR, ' Please reply using a .edu email address');
    //
    //         if (jQ(parent_form).find('input[name="id_form"]').val() == 2) {
    //             email.style.color = "#ECECEC";
    //             email.onclick = function () {
    //                 if (emailERROR) {
    //                     emailERROR.style.visibility = 'hidden';
    //                     if (emailERROR.className.indexOf('email-error-for-edu') !== -1) {
    //                         emailERROR.innerHTML = '';
    //                         email.style.color = "";
    //                     }
    //
    //
    //                     this.onkeydown = null;
    //                 }
    //             }
    //             emailERROR.onclick = function () {
    //                 if (emailERROR) {
    //                     emailERROR.style.visibility = 'hidden';
    //                     if (emailERROR.className.indexOf('email-error-for-edu') !== -1) {
    //                         emailERROR.innerHTML = '';
    //                         email.style.color = "";
    //                     }
    //
    //
    //                     this.onkeydown = null;
    //                 }
    //             }
    //
    //         }
    //         email.focus();
    //         email.onkeydown = function () {
    //             if (emailERROR) {
    //                 emailERROR.style.visibility = 'hidden';
    //                 if (emailERROR.className.indexOf('email-error-for-edu') !== -1) {
    //                     emailERROR.innerHTML = '';
    //                     email.style.color = "";
    //                 }
    //
    //
    //                 this.onkeydown = null;
    //             }
    //         }
    //         return false;
    //     }
    // }
    //
    // var message = jQ(parent_form).find("#ad-info-message").get(0);
    // var messageERROR = jQ(parent_form).find("#ad-info-message-error").get(0);
    // if (!validationInput(message, messageERROR))
    //     return false;


    var agree = jQ(parent_form).find("#agree").get(0);
    /*
     if( jQ(this_elem).parents('.reply-form-special, .reply-form').length ){
     var reply_popup = false;
     }
     else{
     var reply_popup = true;
     }*/


    /*var agreeERROR = jQ(parent_form).find("#ad-info-agree-error").get(0);


     if(!jQ(agree).is(':checked'))
     {
     CMPS_SetErrorText_noneScroll(agreeERROR, jQ(agreeERROR).text());
     agree.focus();
     agree.onchange = function()
     {
     if(agreeERROR)
     {
     agreeERROR.style.visibility = 'hidden';
     this.onchange=null;
     }
     }
     return false;
     }*/

    /*
     +"&agree="+jQ(agree).is(':checked')
     */

    if (slideFlag) {
        jQ(this_elem).attr('disabled', true);
        var reply_form_preloader = '<div class="reply_form_preloader_overlay"><div class="reply_form_preloader"><img src="' + window.imagesDomain + '/img/preloader.gif"/></div></div>';
        jQ(".orange_button_h_36").attr('disabled', true);
        jQ(this_elem).parents(".reply").prepend(reply_form_preloader);
//                jQ(this_elem).parents(".reply").prepend('<div id="modalW" style="width:630px; background: rgba(66, 66, 66, 0.35); margin:-15px 0 -0 -19px; position:absolute;"></div>');
//                jQ('#modalW').height(jQ(this_elem).parents(".reply").height()+30);
//                jQ(this_elem).parents(".reply").prepend('<div id="preloader" style="position:absolute; top: 43%; left: 48%;"><img src="'+window.imagesDomain+'/img/preloader.gif"/></div>');
    }
    else if (jQ(this_elem).parents(".super_popup").length) {
        popup
        ({
            popup_id: "custom_preloader",
            speed: 400,
            close_button: false,
            hide_on_click: false
        });
        // var reply_form_preloader = '<div class="reply_form_preloader_overlay"><div class="reply_form_preloader"><img src="' + window.imagesDomain + '/img/preloader.gif"/></div></div>';
        // jQ(this_elem).parents(".reply").prepend(reply_form_preloader);
    }
    else if (jQ(this_elem).parents(".sidebar_check_avail").length) {
        jQ(this_elem).parents(".reply").prepend('<div class="bg_reply_result_msg"></div>\
                                                         <div class="reply_result_msg"><img src="' + window.imagesDomain + '/img/preloader.gif"/></div>');

    }
    grecaptcha.ready(function () {
        grecaptcha.execute('6LdOo9weAAAAAEzretij8RaZTDlr8nD7AqeHMRtK', {action: 'submit'}).then(function (token) {

            window.CodeAdapter.ajaxRequest({
                url: "index.php",
                data: jQ(this_elem).parents(".reply").serialize() + '&token='+token,
                dataType: "html",
                type: "POST",
                cache: "false",
                success: function (data) {

                    if (data)
                        var data = JSON.parse(data);

                    console.log(data);

                    if (data.response) {
                        if (typeof(data.content) !== 'undefined') {
                            jQ('body').append(data.content);
                        }

                        if (typeof(data.conversion) !== 'undefined') {
                            jQ('body').append('<div id="reply_conversion">' + data.conversion + '</div>');
                        }

                        jQ(".campus-posting-input").not(':button, :submit, :reset, :hidden').val('');

                        if (jQ(".campus-posting-input.posting-select").length) {
                            jQ(".campus-posting-input.posting-select").css("color", "#999");
                        }


                        if (slideFlag) {
                            jQ(this_elem).parents('.reply-form-special, .reply-form').slideUp();
                            requirejs(['popup/notification'], function (msg) {
                                if (data.date_appointment) {
                                    msg.instance.open(data.message);
                                    clearBookTourForm();
                                } else
                                    msg.instance.open('Success, your message has been sent!');
                            });
                            // jQ(this_elem).parents(".reply").parent().parent().after('<p class="reply_sended" style="color:#388908; padding-top:10px; font-size:19px; font-weight:bold;">Success, your message has been sent!</p>');
                        }
                        else if (jQ(this_elem).parents(".sidebar_check_avail").length) {

                            if (data.date_appointment) {
                                jQ('.reply_result_msg').html(data.message).css({
                                    'background': '#fff',
                                    'color': '#388908'
                                });
                                clearBookTourForm();
                            } else {
                                jQ('.reply_result_msg').html('Success, your message has been sent!').css({
                                    'background': '#fff',
                                    'color': '#388908'
                                });
                            }
                        }
                        else {
                            requirejs(['popup/notification'], function (msg) {
                                msg.instance.open('Success, your message has been sent!');
                            });
                        }
                    }
                    else {
                        if (slideFlag) {
                            jQ(this_elem).parents('.reply-form-special, .reply-form').slideUp();

                            if (data.error) {
                                requirejs(['popup/notification'], function (msg) {
                                    msg.instance.open(data.error);
                                });
                                // jQ(this_elem).parents(".reply").parent().parent().after('<p class="reply_sended" style="color:#FF4026; padding-top:10px; font-size:19px; font-weight:bold;">' + data.error + '</p>');
                            }
                            else {
                                requirejs(['popup/notification'], function (msg) {
                                    msg.instance.open('Error in completing the form, please try again\nError in completing the form, please try again');
                                });
                                // jQ(this_elem).parents(".reply").parent().parent().after('<p class="reply_sended" style="color:#FF4026; padding-top:10px; font-size:19px; font-weight:bold;">Error in completing the form, please try again</p>');
                            }
                        }
                        else if (jQ(this_elem).parents(".super_popup").length) {
                            if (data.error) {
                                requirejs(['popup/notification'], function (msg) {
                                    msg.instance.open(data.error);
                                });
                            } else {
                                requirejs(['popup/notification'], function (msg) {
                                    msg.instance.open('Error in completing the form, please try again');
                                });
                            }
                        }
                        else if (jQ(this_elem).parents(".sidebar_check_avail").length) {
                            if (data.error)
                                jQ('.reply_result_msg').html('' + data.error + '').css({'background': '#fff'});
                            else
                                jQ('.reply_result_msg').html('Error in completing the form, please try again').css({'background': '#fff'});
                        }
                    }

                    if (slideFlag) {
//                        jQ("#preloader").remove();
//                        jQ("#modalW").remove();
                        jQ(".reply_form_preloader_overlay").remove();
                        jQ(this_elem).attr('disabled', false);
                        jQ(".orange_button_h_36").attr('disabled', false);
                    }
                    else if (jQ(this_elem).parents(".sidebar_check_avail").length) {
                        setTimeout(function () {
                            jQ(".reply_result_msg, .bg_reply_result_msg").fadeOut(500);
                            jQ(".reply_result_msg, .bg_reply_result_msg").remove();
                        }, 4000);
                    }

                    jQ(".reply_form_preloader_overlay").remove();
                    closePopup("custom_preloader");
                }
            });

        });
    });

    return false;
}

function ReplyByEmailWhiteList(email) {
    var whiteList = ["chris.griffin.2011@gmail.com", "tsyvulskaya@gmail.com"];
    if (whiteList.indexOf(email) !== -1) {
        return true;
    }
    else {
        return false;
    }
}

function sendReserveStorageUnit(elem) {
    var parent = jQ(elem).parents('.storage');
    jQ('.st_result', parent).empty();
    jQ('.campus-posting-error-message', parent).css('visibility', 'hidden');

    if (jQ('#reply_conversion').length) {
        jQ('#reply_conversion').remove();
    }

    var firstName = jQ("input[name$='name']", parent).get(0);
    var firstNameERROR = jQ('.hide_block form', parent).find("#ad-info-name-error").get(0);
    if (!validationInput(firstName, firstNameERROR))
        return false;

    var lastName = jQ("input[name$='last_name']", parent).get(0);
    var lastNameERROR = jQ('.hide_block form', parent).find("#ad-info-last-name-error").get(0);
    if (!validationInput(lastName, lastNameERROR))
        return false;

    var email = jQ("input[name$='email']", parent).get(0);
    var emailERROR = jQ('.hide_block form', parent).find("#ad-info-email-error").get(0);
    if (!validationEmail(email, emailERROR))
        return false;

    var phone1 = jQ("input[name$='phone1']", parent).get(0);
    var phone2 = jQ("input[name$='phone2']", parent).get(0);
    var phone3 = jQ("input[name$='phone3']", parent).get(0);
    var phoneERROR = jQ('.hide_block form', parent).find("#ad-info-phone-error").get(0);
    if (!validationPhone(phone1, phone2, phone3, phoneERROR))
        return false;

    var startDate = jQ("input[name$='start_date']", parent).get(0);
    var startDateERROR = jQ('.hide_block form', parent).find("#ad-info-date-error").get(0);
    if (!validationInput(startDate, startDateERROR))
        return false;

    window.CodeAdapter.ajaxRequest({
        type: "POST",
        url: "index.php",
        data: jQ('.hide_block form', parent).serialize(),
        dataType: 'json',
        success: function (data) {
            switch (data.error_type) {
                case 0:
                    jQ('.st_result', parent).append('<b>Success. Storage was reserved. Your confirmation code is ' + data.mess + '</b><br/><br/>').css({
                        'color': 'green',
                        'display': 'block'
                    });
                    if (data.track.length)
                        jQ('body').append(data.track);

                    jQ('body').append('<div id="reply_conversion"><iframe src="/conversion/storage_reserve_event" width="0px" height="0px"></iframe></div>');
                    break;
                case 1:
                    jQ('.st_result', parent).append('<b>' + data.mess + '!</b><br/><br/>').css({
                        'color': 'red',
                        'display': 'block'
                    });
                    break;
                case 2:
                    jQ('.st_result', parent).append('<b>Error in filling out the fields!</b><br/><br/>').css({
                        'color': 'red',
                        'display': 'block'
                    });
                    break;
            }
        }
    });
    return false;
}

function JoinNews() {
    var form = jQ("#form-join-news");
    var validate = Validate_Data(jQ('input, select, textarea, checkbox', form), false);

    if (!validate) {
        return false;
    }

    jQ('#apply_preloader').show();

    window.CodeAdapter.ajaxRequest({
        url: "index.php",
        data: jQ("#form-join-news").serialize(),
        type: "POST",
        cache: "false",
        success: function (data) {
            data = JSON.parse(data);

            if( typeof(data.trigger_error_label) !== 'undefined'){
                var error_elem = jQ('[name="' + data.trigger_error_label.replace('-error', '') + '"][disabled!="disabled"]', form).get(0);
                if(typeof(error_elem) == 'undefined'){
                    var error_elem = jQ('[id="' + data.trigger_error_label.replace('-error', '') + '"][disabled!="disabled"]', form).get(0);
                }
                if(typeof(data.error) == 'undefined'){
                    data.error = jQ('[id="' + data.trigger_error_label + '"][disabled!="disabled"]', form).text();
                }

                Trigger_Error(error_elem, data.error, true);
            } else if(data.response) {
                jQ('#default_popup .content-inner').html('<div class="join-news-popup"><h2>' + data.response + '</h2></div>');
                ShowPopup('default_popup');
                // jQ('.wrap-join-news-form').before('<div id="send-join-news" style="color:#388908; font-size:20px; clear:left; padding:10px; border:1px solid #cecece; margin-bottom: 20px; width:90%;">Thank you for submitting your application. An editor will be in touch when your application has been reviewed.</div>');
                jQ('body').append('<iframe src="//www.uloop.com/conversion/bing_write_2484" width="0px" height="0px"></iframe>');
                jQ('body').append('<!-- Offer Conversion: Uloop - College Student Writers Wanted --><img src="https://tracking.edvisors.com/aff_l?offer_id=681" width="1" height="1" /><!-- // End Offer Conversion -->');
                jQ('body,html').animate({scrollTop: 0}, 400);
                jQ('#form-join-news').get(0).reset();
            }
        },
        complete: function () {
            jQ('#apply_preloader').hide();
        }

    });
    return false;

    return false;

};

function ChapterAtYourSchool() {
    if ($('jn-info-applying').selectedIndex < 1) {
        CMPS_SetErrorText($('jn-info-applying-error'), 'Please select Editor or a Writer');
        jQ("body").on("change", "#jn-info-applying", function () {
            if ($('jn-info-applying-error')) $('jn-info-applying-error').innerHTML = '';
        });
        return false;
    }
    if (!CMPS_Check_Element('jn-info-email')) {
        CMPS_Trigger_Error('jn-info-email', 'Please enter your Email');
        return false;
    }
    if (!CMPS_Check_Element('jn-info-first-name')) {
        CMPS_Trigger_Error('jn-info-first-name', 'Please enter your First Name');
        return false;
    }
    if (!CMPS_Check_Element('jn-info-last-name')) {
        CMPS_Trigger_Error('jn-info-last-name', 'Please enter your Last Name');
        return false;
    }
    if (!CMPS_Check_Element('jn-info-username')) {
        CMPS_Trigger_Error('jn-info-username', 'Please enter your Username ');
        return false;
    }
    var phone1 = $('jn-info-phoneP1');
    var phone2 = $('jn-info-phoneP2');
    var phone3 = $('jn-info-phoneP3');
    if ((phone1.value.length < 3 || !isInteger(phone1.value)) || (phone2.value.length < 3 || !isInteger(phone2.value)) || (phone3.value.length < 4 || !isInteger(phone3.value))) {
        CMPS_SetErrorText($('jn-info-phone-error'), '  Please enter valid phone number');
        jQ("body").on("keydown", "#jn-info-phoneP1, #jn-info-phoneP2, #jn-info-phoneP3", function () {
            if ($('jn-info-phone-error')) $('jn-info-phone-error').innerHTML = '';
        });
        $('jn-info-phoneP1').focus();
        return false;
    }
    if ($('jn-info-college').selectedIndex < 1) {
        CMPS_SetErrorText($('jn-info-college-error'), 'Please select your College');
        jQ("body").on("change", "#jn-info-college", function () {
            if ($('jn-info-college-error')) $('jn-info-college-error').innerHTML = '';
        });
        return false;
    }
    if ($('jn-info-graduation-date').selectedIndex < 1) {
        CMPS_SetErrorText($('jn-info-graduation-date-error'), 'Please select Graduation Date');
        jQ("body").on("change", "#jn-info-graduation-date", function () {
            if ($('jn-info-graduation-date-error')) $('jn-info-graduation-date-error').innerHTML = '';
        });
        return false;
    }
    if (!CMPS_Check_Element('jn-info-major')) {
        CMPS_Trigger_Error('jn-info-major', 'Please enter your major/area');
        return false;
    }
    if ($('jn-info-previous-writing').value.length == 0 || CMPS_hasHTMLTags($('jn-info-previous-writing').value) || CMPS_Trim($('jn-info-previous-writing').value).length == 0) {
        if ($('jn-info-previous-writing').value.length == 0 || CMPS_Trim($('jn-info-previous-writing').value).length == 0)
            CMPS_SetErrorText($('jn-info-previous-writing-error'), ' Please enter your Previous writing/Editing experience');
        else if (CMPS_hasHTMLTags($('jn-info-previous-writing').value))
            CMPS_SetErrorText($('jn-info-previous-writing-error'), ' Please enter text only');
        $('jn-info-previous-writing').focus();
        addCustomEvent('jn-info-previous-writing', '', 'keydown');
        return false;
    }
    if ($('jn-info-experience').value.length == 0 || CMPS_hasHTMLTags($('jn-info-experience').value) || CMPS_Trim($('jn-info-experience').value).length == 0) {
        if ($('jn-info-experience').value.length == 0 || CMPS_Trim($('jn-info-experience').value).length == 0)
            CMPS_SetErrorText($('jn-info-experience-error'), ' Please enter your Attach one or two samples of your recent experience');
        else if (CMPS_hasHTMLTags($('jn-info-experience').value))
            CMPS_SetErrorText($('jn-info-experience-error'), ' Please enter text only');
        $('jn-info-experience').focus();
        addCustomEvent('jn-info-experience', '', 'keydown');
        return false;
    }
    if (!CMPS_Check_Element('letter-explaining')) {
        CMPS_Trigger_Error('letter-explaining', 'Please include a cover letter explaining');
        return false;
    }
    if (!CMPS_Check_Element('chapter-resume')) {
        CMPS_Trigger_Error('chapter-resume', 'Please include your resume');
        return false;
    }
    if ($('jn-info-samples-work').value.length == 0 || CMPS_hasHTMLTags($('jn-info-samples-work').value) || CMPS_Trim($('jn-info-samples-work').value).length == 0) {
        if ($('jn-info-samples-work').value.length == 0 || CMPS_Trim($('jn-info-samples-work').value).length == 0)
            CMPS_SetErrorText($('jn-info-samples-work-error'), ' Please enter your Attach one or two samples of your recent experience');
        else if (CMPS_hasHTMLTags($('jn-info-samples-work').value))
            CMPS_SetErrorText($('jn-info-samples-work-error'), ' Please enter text only');
        $('jn-info-samples-work').focus();
        addCustomEvent('jn-info-samples-work', '', 'keydown');
        return false;
    }
    if ($('jn-info-ideas').value.length == 0 || CMPS_hasHTMLTags($('jn-info-ideas').value) || CMPS_Trim($('jn-info-ideas').value).length == 0) {
        if ($('jn-info-ideas').value.length == 0 || CMPS_Trim($('jn-info-ideas').value).length == 0)
            CMPS_SetErrorText($('jn-info-ideas-error'), ' Please enter your Attach one or two samples of your recent experience');
        else if (CMPS_hasHTMLTags($('jn-info-ideas').value))
            CMPS_SetErrorText($('jn-info-ideas-error'), ' Please enter text only');
        $('jn-info-ideas').focus();
        addCustomEvent('jn-info-ideas', '', 'keydown');
        return false;
    }
    if ($('jn-info-interested').value.length == 0 || CMPS_hasHTMLTags($('jn-info-interested').value) || CMPS_Trim($('jn-info-interested').value).length == 0) {
        if ($('jn-info-interested').value.length == 0 || CMPS_Trim($('jn-info-interested').value).length == 0)
            CMPS_SetErrorText($('jn-info-interested-error'), ' Please enter your Attach one or two samples of your recent experience');
        else if (CMPS_hasHTMLTags($('jn-info-interested').value))
            CMPS_SetErrorText($('jn-info-interested-error'), ' Please enter text only');
        $('jn-info-interested').focus();
        addCustomEvent('jn-info-interested', '', 'keydown');
        return false;
    }

    jQ('#apply_preloader').show();

    window.CodeAdapter.ajaxRequest({
        url: "index.php",
        data: jQ("#form-join-news").serialize(),
        dataType: "html",
        type: "POST",
        cache: "false",
        success: function (data) {

            data = ~~data;
            switch (data) {
                case 5:
                {
                    CMPS_Trigger_Error('jn-info-username', 'This username is already used, please choose another one.');
                }
                    ;
                    break;

                case 6:
                {
                    CMPS_Trigger_Error('jn-info-email', 'This email is already used, please choose another one.');
                }
                    ;
                    break;

                case 7:
                {
                    CMPS_Trigger_Error('jn-info-email', 'Please enter valid Email Address');
                }
                    ;
                    break;

                case 1:
                {
                    jQ('#send-join-news').remove();
                    jQ('.wrap-join-news-form').before('<div id="send-join-news" style="color:#388908; font-size:20px; clear:left; padding:10px; border:1px solid #cecece; margin-bottom: 20px; width:609px;">Thank you for submitting your application. An editor will be in touch when your application has been reviewed.</div>');
                    jQ('body,html').animate({scrollTop: 0}, 400);
                    jQ('#form-join-news').get(0).reset();
                }
                    ;
                    break;
                default:
                    return false;
            }
            jQ('#apply_preloader').hide();
        }

    });
    return false;

    return false;

};

function page_load_storage(location) {

    var popup_id = "get_zip";
    if (typeof(plugin_data) !== 'undefined') {
        var container = jQ(".uloop-content").last();
        var method = 'get';
    } else {
        var container = jQ("#footer");
        var method = 'post';
    }
    container.append("<div class=\"popup_overlay\" id=\"popup_overlay\"></div><div class=\"super_popup\" id=\"get_zip\"><div class=\"sgn_in_popup\"><h2>Please enter your zip code: </h2><form name=\"zip_for_storage\" class=\"popup_form only_num \" id=\"zip_for_storage_form\" action=\"" + location + "\" method=\"" + method + "\" onsubmit=\"return submitStZip();\"><div><span style=\"color:red; font-size: 14px; display:none;\" id=\"ZipForStorage_error\"> Please enter your zip code!</span><input type=\"text\" name=\"st_zip\" class=\"popup_input\" size=\"35\"/><div class=\"clear\"></div><input type=\"submit\" name=\"Input\" value=\"Submit\" class=\"orange_button_h_36\"/><br/><br/></div></form></div></div>");
    popup
    ({
        popup_id: popup_id,
        speed: 100,
        close_button: false,
        hide_on_click: false
    });
}

function page_load_tickets(close_button, hide_on_click) {

    close_button = typeof(close_button) === 'undefined' ? true : close_button;
    hide_on_click = typeof(hide_on_click) === 'undefined' ? true : hide_on_click;


    if (typeof(plugin_data) !== 'undefined') {
        var popup_id = "tickets_school";

        jQ('.uloop-content').last().append('<div class="popup_overlay" id="popup_overlay"></div><div class="super_popup" id="tickets_school"><div class="sgn_in_popup">\
	<div class="find-your-school">\
	<div class="find-your-school-title">Enter College Name to See Local Results</div>\
<form class="find-your-school-form">\
			<input type="hidden" class="find-your-school-slug" value="">\
			<input type="text" class="find-your-school-input" value="Enter College Name">\
			<input type="submit" value="Go" class="find-your-school-submit">\
		<input type="hidden" name="verification" value="1"></form></div>\
</div></div>');

    }
    else {
        var popup_id = "search_popup";
        // jQ("#" + popup_id + " .search_popup").addClass('find_your_school');
//        jQ("#"+popup_id+" h2").text("Find Your School");
    }


    window.popup2
    ({
        popup_id: popup_id,
        speed: 100,
        close_button: close_button,
        hide_on_click: hide_on_click
    });
}


function sendContactUs(form) {
    var username = jQ(form).find("input[name$='name']").get(0);
    var usernameERROR = jQ(form).find("#name-error").get(0);
    if (!validationInput(username, usernameERROR))
        return false;

    var email = jQ(form).find("input[name$='email']").get(0)
    var emailERROR = jQ(form).find("#email-error").get(0);
    if (!validationEmail(email, emailERROR))
        return false;

    var message = jQ(form).find("textarea[name$='message']").get(0);
    var messageERROR = jQ(form).find("#message-error").get(0);
    if (!validationInput(message, messageERROR))
        return false;


    window.CodeAdapter.ajaxRequest({
        url: "index.php",
        data: jQ(form).serialize(),
        dataType: "html",
        type: "POST",
        cache: "false",
        success: function (data) {

            data = ~~data;

            if (data === 1) {

                jQ('#contact_us_popup').hide();

                jQ('body').append('<div class="super_popup" id="contact_info">\
                                                        <div>\
                                                                <h2>Success. Your Message is sent!</h2>\
                                                        </div>\
                                        </div>');

                popup
                ({
                    popup_id: "contact_info",
                    speed: 100,
                    close_button: false
                });

                setTimeout(function () {
                    location.reload();
                }, 2000);
            }
            else if (data === 2) {
                var contactCheck = jQ(form).find("input[name$='contactCheck']").get(0);
                var contactCheckERROR = jQ(form).find("#validation-error").get(0);
                if (!validationShowError(contactCheck, contactCheckERROR))
                    return false;
            }
            else {

            }

        }
    });
    return false;
}


function AdPostingCustomFields() {
    jQ('.custom_fields_block').hide();
    jQ('.custom_fields_block').find('input, textarea, button, select').attr('disabled', 'disabled');
    jQ('.custom_fields_block').find('.campus-posting-error-message').attr('disabled', 'disabled');

    var channelID = jQ('#ad-global-channel').val();

    if (jQ('#ad-info-house-property-type').get(0) && (jQ('#ad-info-house-property-type').val() == 1028 || jQ('#ad-info-house-property-type').val() == 1030  )) {
        var categoryID = jQ('#ad-info-house-property-type').val();
    }
    else {
        var categoryID = jQ('#ad-main-subcategories-list').val();
    }

    var state = jQ('#ad-info-state-jobs').val();

    console.log('state', state);
    // if(window.CodeAdapter.project_type !== 'wordpress_plugin' && typeof(CampusGlobalSubCategoriesHash) !== typeof undefined && typeof(CampusGlobalSubCategoriesHash[categoryID]) !== typeof undefined) {
    //   var price = parseInt(CampusGlobalSubCategoriesHash[categoryID].price);
    //   if (price > 0 && (channelID != 3 || channelID == 3 && jQ('#ad-info-house-property-type').val() > 0)) {
    //     jQ('#ad-main-subcategory-price').html('$' + price + ' for a <span>30-day listing</span>');
    //   } else {
    //     jQ('#ad-main-subcategory-price').html('');
    //   }
    // }

    if (jQ('.channel-' + channelID + '-category-' + categoryID).length) {
        jQ('.channel-' + channelID + '-category-' + categoryID).show();
        jQ('.channel-' + channelID + '-category-' + categoryID).find('input, textarea, button, select').removeAttr('disabled');
        jQ('.channel-' + channelID + '-category-' + categoryID).find('.campus-posting-error-message').removeAttr('disabled');
    }
    if (jQ('.channel-' + channelID + '-category-all').length) {
        jQ('.channel-' + channelID + '-category-all').show();
        jQ('.channel-' + channelID + '-category-all').find('input, textarea, button, select').removeAttr('disabled');
        jQ('.channel-' + channelID + '-category-all').find('.campus-posting-error-message').removeAttr('disabled')
    }

    if (jQ('.channel-' + channelID + '-category-all-state-' + state).length) {
        jQ('.channel-' + channelID + '-category-all-state-' + state).show();
        jQ('.channel-' + channelID + '-category-all-state-' + state).find('input, textarea, button, select').removeAttr('disabled');
        jQ('.channel-' + channelID + '-category-all-state-' + state).find('.campus-posting-error-message').removeAttr('disabled')
    }

}

function ShowPopup(id_block) {
    // if (id_block === 'reply_by_email_popup') {
    //     import('./react/renderPopup').then(({renderPopup}) => {
    //         renderPopup('reply_by_email_popup').then(() => {
    //             popup
    //             ({
    //                 popup_id: id_block,
    //                 speed: 100
    //             });
    //         });
    //     });
    //
    //     return false;
    // }

    if (jQ('#' + id_block + ' .popup-script').length) {
        var popupScript = document.createElement('script');
        popupScript.src = jQ('#' + id_block + ' .popup-script').attr('src');
        document.head.appendChild(popupScript);
    }

    popup
    ({
        popup_id: id_block,
        speed: 100
    });
    return false;
}

function closePopup(popup_id) {
    jQ('#' + popup_id + ', .popup_overlay').hide();
}

function ShowCustomPopup(message) {
    if (!jQuery('#custom_message').length) {
        jQuery('body').append('\
							<div class="super_popup" id="custom_message">\
								<div style="padding: 28px 30px 32px 30px; width: 300px;">\
									<h2 id="custom_message_container"></h2>\
								</div>\
							</div>');
    }

    jQuery("#custom_message_container").html(message)

    popup
    ({
        popup_id: 'custom_message',
        speed: 100
    });
    return false;
}

function confirm_email(login) {
    if (window.CodeAdapter.project_type === 'wordpress_plugin') {
        jQ('#send_confirm_msg_info div').html('<h2>Confirmation email has been sent to your email!</h2>');
    }

    window.CodeAdapter.ajaxRequest({
        url: "index.php",
        data: 'action=send_confirm_email&login=' + login,
        dataType: "html",
        type: "POST",
        success: function (data) {

            data = ~~data;

            if (data == 1) {
                if (window.CodeAdapter.project_type === 'wordpress_plugin') {
                    ShowPopup('send_confirm_msg_info');
                } else {
                    requirejs(['popup/notification'], function (msg) {
                        msg.instance.open('Confirmation email has been sent to your email!');
                    });
                }
            }
        }
    });
    return false;
}

function confirm_pass(login) {
    jQ('#send_confirm_msg_info div').html('<h2>Confirmation password has been sent to your email!</h2>');
    window.CodeAdapter.ajaxRequest({
        url: "index.php",
        data: 'action=send_confirm_password&login=' + login,
        dataType: "html",
        type: "POST",
        success: function (data) {

            data = ~~data;

            if (data == 1) {
                ShowPopup('send_confirm_msg_info');
            }
        }
    });
    return false;
}
function PrintReceipt(text) {
    var w = window.open("", "_rcpt", "width=870,height=865");
    w.document.write('<html><head><link href="" rel="stylesheet" type="text\css"></head><body>' + text + '</body></html>');
    w.print();
    return false;

}

function showUploadDialog_HTML5(elem, upload_id, reset, errorField, parrentClass) {

    var ImputUpload = '';
    parrentClass = jQ(elem).closest(parrentClass).attr('class');
    window.parentElem = parrentClass.length ? '.' + parrentClass + ' ' : '';

    if (upload_id && upload_id.length) {
        ImputUpload = jQ(window.parentElem + '#' + upload_id);
        if (!ImputUpload)
            return;
    }
    else
        return;

    if (reset) {
        jQ(window.parentElem + '#' + upload_id).val('');
    }

    if (errorField.length) {
        jQ(window.parentElem + errorField).html('');
    }

    ImputUpload.click();
}


function uploadFile_HTML5(fileInput, onSuccess_HTML5, onError_HTML5, onProgress_HTML5, clearFunction, parrentClass, ajax_path) {
    var file = fileInput.files[0];
    var ajax_path = ajax_path || 'upload-files.php'
    if (!file)
        return;

    var xhr = new XMLHttpRequest();


    xhr.onload = xhr.onerror = function (param) {
        if (this.status != 200 && xhr.responseText.length == 0) {
            if (onError_HTML5.length) {
                window[onError_HTML5](this, parrentClass);
            }
            return;
        }

        if (onSuccess_HTML5.length) {
            window[onSuccess_HTML5](xhr.responseText, parrentClass);
        }
    };


    if (onProgress_HTML5.length) {
        xhr.upload.onprogress = function (event) {
            window[onProgress_HTML5](clearFunction, parrentClass);
        }
    }
    ;

    var oMyForm = new FormData();

    oMyForm.append("Filedata", file);

    xhr.open("POST", ajax_path);
    xhr.send(oMyForm);
}


function uploadLogoFile_HTML5(fileInput, onSuccess_HTML5, onError_HTML5, onProgress_HTML5, clearFunction) {
    var file = fileInput.files[0];
    if (!file)
        return;

    var xhr = new XMLHttpRequest();


    xhr.onload = xhr.onerror = function (param) {
        if (this.status != 200 && xhr.responseText.length == 0) {
            if (onError_HTML5.length) {
                window[onError_HTML5](this);
            }
            return;
        }

        if (onSuccess_HTML5.length) {
            window[onSuccess_HTML5](xhr.responseText);
        }
    };


    if (onProgress_HTML5.length) {
        xhr.upload.onprogress = function (event) {
            window[onProgress_HTML5](clearFunction);
        }
    }
    ;

    var oMyForm = new FormData();

    oMyForm.append("Filedata", file);

    xhr.open("POST", "upload-logos.php");
    xhr.send(oMyForm);
}

function uploadResumeProgress_HTML5(clearFunction, parrentClass) {
    if (clearFunction.length)
        window[clearFunction](jQ(window.parentElem + '.resume_remove'), parrentClass);
    jQ(window.parentElem + ' .resume_preloader').css('display', '');
    jQ(window.parentElem + ' #ad-info-resume-error').css('visibility', 'hidden');
}

function uploadResumeSuccess_HTML5(response) {
    jQ(window.parentElem + ' .resume_preloader').css('display', 'none');
    jQ(window.parentElem + ' .error_upload').html('');

    if (!response)
        return;

    if (response.indexOf('Upload ') !== -1 || response.indexOf('upload ') !== -1 || response.indexOf(' only') !== -1) {
        uploadRemoveResume(jQ(window.parentElem + '.resume_remove'), window.parentElem);
        jQ(window.parentElem + ' .error_upload').html(response);
        CMPS_blink(jQ(window.parentElem + ' .error_upload')[0]);

    }
    // else if (response.indexOf('.pdf') !== -1)
    //     jQ(window.parentElem + ' .resume_icon').attr('src', window.imagesDomain + '/img/icon/pdf_icon.png').css('display', '');
    // else if (response.indexOf('.doc') !== -1 || response.indexOf('.docx') !== -1)
    //     jQ(window.parentElem + ' .resume_icon').attr('src', window.imagesDomain + '/img/icon/word_icon.png').css('display', '');

    // if (response.indexOf('.pdf') !== -1 || response.indexOf('.doc') !== -1 || response.indexOf('.docx') !== -1) {
    if (response.indexOf('.pdf') !== -1) {
        jQ(window.parentElem + ' .resume_view').attr('href', response);
        jQ(window.parentElem + ' .doc_link').attr('value', response);

        jQ(window.parentElem + '.action_resume').fadeIn();
    }

    // addResumeIconEvent();

}

function uploadContactFileProgress_HTML5(clearFunction, parrentClass) {
}

function uploadContactFileSuccess_HTML5(response) {
    response = JSON.parse(response);
    if (typeof response != typeof undefined && typeof response.error != typeof undefined) {
        requirejs(['popup/notification'], function (msg) {
            msg.instance.open(response.error);
        });
        return false;
    } else {
        requirejs(['pages/InviteFriends'], function (obj) {
            obj.instance.buildEmailInviteForm();
        });
    }
    console.log('uploadContactFileSuccess_HTML5', response);
}

function uploadError_HTML5() {
    alert('Error upload file');
}

function addResumeIconEvent() {
    jQ(window.parentElem + ' .resume_icon').on('click', function (elem) {
        var currenParentActionElem = jQ(this).parent().parent().attr('class');
        currenParentActionElem = currenParentActionElem.length ? '.' + currenParentActionElem + ' ' : '';

        if (jQ(currenParentActionElem + '.action_resume').css('display') != 'none')
            jQ(currenParentActionElem + '.action_resume').fadeOut();
        else
            jQ(currenParentActionElem + '.action_resume').fadeIn();
    });
}

function RemoveResumeIconEvent(elem) {
    var currenParentRemoveElem = '';
    if (elem && jQ(elem).length)
        currenParentRemoveElem = jQ(elem).closest(window.parentElem).attr('class');

    currenParentRemoveElem = currenParentRemoveElem.length ? '.' + currenParentRemoveElem + ' ' : '';

    jQ('body').off('click', currenParentRemoveElem + '.resume_icon');
    jQ(currenParentRemoveElem + '.action_resume').hide();
}

function uploadRemoveResume(elem) {
    var currentParentElem = '';
    if (elem && jQ(elem).length)
        currentParentElem = jQ(elem).parent().parent().parent().attr('class');

    currentParentElem = currentParentElem.length ? '.' + currentParentElem + ' ' : '';

    jQ(currentParentElem + '.resume_icon').off('click');

    jQ(currentParentElem + '.action_resume').hide();
    jQ(currentParentElem + '.resume_icon').attr('src', window.imagesDomain + '/img/letters/spacer.png');
    jQ(currentParentElem + '.resume_view').attr('href', '');
    jQ(currentParentElem + '.doc_link').attr('value', '');
}

function addOnLoadFunction(func) {
    if (window.addEventListener) {
        window.addEventListener('load', func);
    } else if (window.attachEvent) {
        window.attachEvent('onload', func);
    }

}

function addCustomEvent(elem_id, elem_error_id, event) {
    var elem = document.getElementById(elem_id);

    if (!elem_error_id.length)
        elem_error_id = elem_id + '-error';

    var elem_error = document.getElementById(elem_error_id);

    if (typeof(elem) !== "undefined" && elem != null && elem.addEventListener) {
        elem.addEventListener(event, function () {
            if (typeof(elem_error) !== 'undefined') {
                elem_error.innerHTML = '';
                elem_error.style.visibility = 'hidden';
            }
            this.keydown = null;
        }, false);
    }
    else if (typeof(elem) !== "undefined" && elem != null && elem.attachEvent) {
        elem.attachEvent("on" + event, function () {
            if (typeof(elem_error) !== 'undefined') {
                elem_error.innerHTML = '';
                elem_error.style.visibility = 'hidden';
            }
            this.keydown = null;
        });
    }
}

function show_collegeNewspapers() {
    window.CodeAdapter.ajaxRequest({
        url: "/ajax_content.php",
        data: {action: 'get_college_newspapers_list'},
        success: function (data) {
            if (window.CodeAdapter.project_type === 'wordpress_plugin') {
                jQ("#college_newspaper_popup div").html(data);
            } else {
                requirejs(['popup/notification'], function (msg) {
                    msg.instance.showNotice(data, 580);
                });
            }
        }
    });
    //jQ("#college_newspaper_popup div").load("/college_newspapers_list.html");
    if (window.CodeAdapter.project_type === 'wordpress_plugin') {
        ShowPopup('college_newspaper_popup');
    }
    return false;
}

function showReplyPopup(elem, listingID, title, link, desc, img, location, price, rooms, bathrooms, num_photo, page_from) {
    var post_html = '';
    if (img) {
        post_html += '<div class="post_image">\
                            <div class="block_for_image">\
                                <div class="display_table">\
                                    <a href="' + link + '"><img src="' + img + '">' + (num_photo.length ? '<div class="photo_count">' + num_photo + '</div>' : '') + '</a>\
                                </div>\
                            </div>\
                        </div>\
                        <div class="post_info_has_image">';
    }
    else {
        post_html += '<div class="post_info_no_has_image>';
    }

    post_html += '<div class="post_text_l">\
                                <a class="title" href="javascript:;">' + title + '</a>\
                                <div class="subdesc">\
                                    <div class="category_page_location subdesc_line">' + location + '</div>\
                                    <div class="category_page_price subdesc_line">' + price + '</div>\
                                    <div class="category_page_rooms subdesc_line">' + (rooms.length ? '<b class="h_beds"></b>' + rooms + '<b class="space"></b>' : '') + ' ' + (bathrooms.length ? '<b class="h_bath"></b>' + bathrooms : '') + '</div>\
                                </div>\
                                <div class="listing_short_description">' + desc + '</div>\
                           </div>\
                    </div>';

    jQ('#reply_by_email_popup [name$="id_listing"]').val(listingID);
    jQ('#reply_by_email_popup [name$="page_from"]').val(page_from);
    jQ('#reply_by_email_popup [name$="page_from"]').val(page_from);
    jQ('#reply_by_email_popup [name$="message"]').text("Hello,\n\nI'm interested in " + title + ". Please send me current availability and additional details.\n\nThanks.");
    jQ('#reply_by_email_popup .campus-posting-input').val('');
    jQ('#reply_by_email_popup .post').html(post_html);

    ShowPopup('reply_by_email_popup');
}

function showReplyPopupSimple(listingID, id_form) {

    jQ('#reply_by_email_popup [name$="id_listing"]').val(listingID);
    jQ('#reply_by_email_popup').append('<input type="hidden" name="id_form" value="' + id_form + '"/>');
    jQ('#reply_by_email_popup .campus-posting-input').val('');

    ShowPopup('reply_by_email_popup');
}

function OpenCustomeListingPreview(RealLink, Link, additional_img) {

    if (jQ(window).width() <= 695) {
        window.location = RealLink;
        return '';
    }


    var topParam = ((jQ(window).height()) / 2 + jQ(window).scrollTop() + "px");
    jQ('.loading').css('top', topParam);

    jQ('.popup_overlay, .loading').fadeIn();
    window.CodeAdapter.ajaxRequest({
        url: Link,
        data: (typeof(additional_img) !== 'undefined' ? 'additional_img=' + additional_img : ''),
        type: "POST",
        success: function (data) {

            jQ('.loading').hide();
            jQ('#custom_listing_preview').html(data);

            processUloopExternalLinks();

            //////
            if (typeof UloopCustomSliderClass === 'function') {
                window.UloopCustomSlider2 = new UloopCustomSliderClass();
                UloopCustomSlider2.SliderInit({
                    slider: ".custom_page_big_slider",
                    wrap_big_image: ".custom_page_big_image",
                    use_preview: false,
                    image_index: "#detail_current_photo",
                    resize_width: false
                });

                window.UloopCustomSlider3 = new UloopCustomSliderClass();
                UloopCustomSlider3.SliderInit({
                    slider: ".custom_page_floorplan_big_slider",
                    wrap_big_image: ".custom_page_big_image",
                    use_preview: false,
                    image_index: "#detail_current_unit_photo",
                    resize_width: false
                });
            }
            jQ(".listing_units .floorlan_action").click(function () {
                actionUnitDescribe(this);
            });

            var video = document.getElementById("uloop_video");
            var videoPlay = false;

            if (jQ(window).width() <= 695 && (!jQ('#detail_tabs_line').hasClass('mobile') || jQ('#tab_content .tab').hasClass('mobile'))) {
                jQ('#detail_tabs_line, #tab_content .tab').addClass("mobile");
                jQ('#detail_tabs_line li').eq(0).addClass("active");
                jQ("#tab_content .tab").eq(0).addClass("active");
            }
            else if (jQ(window).width() > 695 && (!jQ('#detail_tabs_line').hasClass('mobile') || jQ('#tab_content .tab').hasClass('mobile'))) {
                jQ('#updateSearchLocation, #tab_content .tab').removeClass("mobile");
                jQ('#detail_tabs_line li').eq(1).addClass("active");
            }


            jQ("#detail_tabs_line li").each(function (i, e) {
                jQ("#detail_tabs_line li").click(function () {
                    var tab_id = jQ(this).find('a').attr("default");
                    jQ("#detail_tabs_line li").removeClass("active");
                    jQ(this).addClass("active");
                    if (tab_id.indexOf('tab_video_content') != -1) {
                        video.play();
                        videoPlay = true;
                    }
                    else if (videoPlay) {
                        video.pause();
                    }
                    jQ("#tab_content .tab").stop(false, false).animate({opacity: "0"}, 300, function () {
                        jQ(this).css({"position": "absolute", "z-index": "0"}).removeClass("active");
                        jQ("#" + tab_id).stop(false, false).animate({
                            opacity: "1",
                            "z-index": "1"
                        }, 300).css({position: "relative"}).addClass("active");
                    });

                    return false;
                });
            });
            ////

            Track();

            popup
            ({
                popup_id: 'custom_listing_preview',
                speed: 100,
                top: 200
            });

            var reply_check_input = document.createElement('input');
            reply_check_input.setAttribute('type', 'hidden');
            reply_check_input.setAttribute('name', 'reply_check_input');
            reply_check_input.setAttribute('value', '1');

            jQ(".popup_preview input[name='action'][value='reply_by_email']").closest('form').get(0).appendChild(reply_check_input);

            if(typeof resizeAll === "function"){
                resizeAll();
            }
        },
        error: function (xhr, ajaxOptions, thrownError) {
            jQ('.popup_overlay, .loading').hide();
            if(typeof resizeAll === "function"){
                resizeAll();
            }
        }
    });
}

function showAlertPopup(topText, topsubText, chi, cai, search) {
    if (!topText.length) {
        jQ('#createAlert #createAlert_info').css({"border-bottom": "none", "padding": "0"});
    }
    jQ('#createAlert #createAlert_info p').html(topText);
    jQ('#createAlert #static_text').html(topsubText);
    jQ('#createAlert input[name="channel"]').val(chi);
    jQ('#createAlert input[name="category"]').val(cai);
    jQ('#createAlert input[name="s"]').val(search);
    ShowPopup('createAlert');
}

function showUsaTodayAlertPopup(topText, topsubText, chi, cai, search) {
    if (!topText.length) {
        jQ('#createAlert #createAlert_info').css({"border-bottom": "none", "padding": "0"});
    }
    jQ('#createAlert #createAlert_info p').html(topText);
    jQ('#createAlert #static_text').html(topsubText);
    jQ('#createAlert input[name="channel"]').val(chi);
    jQ('#createAlert input[name="category"]').val(cai);
    jQ('#createAlert input[name="s"]').val(search);

    if (typeof(popup) !== 'undefined') {
        popup
        ({
            popup_id: 'createAlert',
            speed: 100
        });
    }
    return false;

}

function shareToTweet(e) {
    e.preventDefault();
    e.stopPropagation();


    var elem = e.currentTarget,
        item_id = ~~jQ(elem).attr("data-id"),
        track_id = ~~jQ(elem).attr("track-id"),
        track_type = ~~jQ(elem).attr("track-type"),
        goal_id = ~~jQ(elem).attr("goal-id"),
        goal_type = ~~jQ(elem).attr("goal-type");

    trackSocialCkicl(item_id, track_id, track_type);
    triggerConversionGoal(goal_type, {listing_id:item_id, additional:goal_id});

    var url = jQ(elem).attr('href'),
        text = jQ(elem).attr('data');
    window.open('https://twitter.com/intent/tweet?related=uloop&text=' + text + '&url=' + url + '&original_referer=' + window.location.origin, '', 'height=430, width=500, top=300, left=300, scrollbars=1');

    return false;
}


function shareToFacebook(e, url, id) {
    e.preventDefault();
    e.stopPropagation();

    var elem = e.currentTarget,
        item_id = ~~jQ(elem).attr("data-id"),
        track_id = ~~jQ(elem).attr("track-id"),
        track_type = ~~jQ(elem).attr("track-type"),
        goal_id = ~~jQ(elem).attr("goal-id"),
        goal_type = ~~jQ(elem).attr("goal-type");

    var url = url || jQ(elem).attr('href'),
        id = id || jQ(elem).attr('data-id');

    var waitOnFBInitialization = setInterval(function () {
        if (window.fb_initialized) {
            clearInterval(waitOnFBInitialization)

            FB.ui({
                method: "share",
                href: url,
            }, function (response) {
                if (response && !response.error_code) {
                    trackSocialCkicl(item_id, track_id, track_type);
                    triggerConversionGoal(goal_type, {listing_id:item_id, additional:goal_id});
                }
            });
        }
    }, 100);

    return false;
}

function shareToFacebookAndFeature(e, id, hash) {
    e.preventDefault();
    e.stopPropagation();

    var js = 'facebook-jssdk';
    var id = 'facebook-jssdk';
    if (document.getElementById(id)) {
        return;
    }
    js = document.createElement('script');
    js.id = id;
    js.async = false;
    js.src = "//connect.facebook.net/en_US/all.js";
    document.getElementsByTagName('head')[0].appendChild(js);

    window.fbAsyncInit = function () {
        FB.init({
            appId: '446108658839914', // old 454900215432
            status: true,
            cookie: true,
            xfbml: true,
            oauth: true
        });

        document.dispatchEvent(new Event("fb-init"));
    }

    window.fb_initialized = window.fb_initialized || false;

    document.addEventListener("fb-init", function (ev) {
        if (!window.fb_initialized) {
            window.fb_initialized = true;
        }
    });

    var elem = e.currentTarget,
        url = jQ(elem).attr('href');

    if(url == '#'){
        return false;
    }

    var waitOnFBInitialization = setInterval(function () {
        if (window.fb_initialized) {
            clearInterval(waitOnFBInitialization)

            FB.ui({
                method: "share",
                href: url,
            }, function (response) {
                if (response && !response.error_code) {
                    window.share_and_feature_popup_obj.instance.popup.close();

                    window.CodeAdapter.ajaxRequest({
                        url: "ajaxapi.php",
                        data: "facebook_share_and_feature=1&ad=" + ~~id + "&hash=" + hash,
                        dataType: "json",
                        type: "POST",
                        cache: "false",
                        success: function(data){
                            if(data.msg) {
                                jQ('#share-and-feature-popup .content-inner').html('<h2 style="margin-bottom: 0;">'+data.msg+'</h2>');
                                ShowPopup("share-and-feature-popup");
                            }
                        }
                    });
                }
            });
        }
    }, 100);

    return false;
}

function initFBSDK() {
    //Load the SDK Asynchronously
    var js = 'facebook-jssdk';
    var id = 'facebook-jssdk';
    if (document.getElementById(id)) {
        return;
    }
    js = document.createElement('script');
    js.id = id;
    js.async = false;
    js.src = "//connect.facebook.net/en_US/all.js";
    document.getElementsByTagName('head')[0].appendChild(js);

    window.fbAsyncInit = function () {
        FB.init({
            appId: '446108658839914', // old 454900215432
            status: true,
            cookie: true,
            xfbml: true,
            oauth: true
        });

        document.dispatchEvent(new Event("fb-init"));
    }

    window.fb_initialized = window.fb_initialized || false;

    document.addEventListener("fb-init", function (ev) {
        if (!window.fb_initialized) {
            window.fb_initialized = true;
        }
    });
}

function initFBComments() {
    var commentsIntoView = false;
    jQ(window).scroll(function (event) {
        if (!commentsIntoView && isScrolledIntoView('.facebookComment')) {
            commentsIntoView = true;
            initFBSDK();
        }

    });
}

function InitNewsGetSocialPopup() {
    if (jQ('#NewsGetSocialPopup').length) {
        jQ('#NewsGetSocialPopup').remove();
    }

    // jQ('body').append(popup);

    var showed = false;
    var lastScrollTop = 0;
    var articleEnd = false;
    jQ(window).scroll(function (event) {
        if (!articleEnd && isScrolledIntoView('.article_tags')) {
            articleEnd = true;
        }

        var st = jQ(this).scrollTop();
        if (st > lastScrollTop) {
            // downscroll
        } else if (!showed && articleEnd) {
            event.stopPropagation();
            // upscroll

            initFBSDK();

            const jsTag = document.createElement("script");
            jsTag.type = "text/javascript";
            jsTag.async = true;
            jsTag.src = '//platform.twitter.com/widgets.js';
            document.head.appendChild(jsTag);

            function openSocialPopup() {
                requirejs(['plugins/popupScrollable'], function (popupScrollable) {

                    var infoPopup = popupScrollable.create({
                        maxWidth: 350,
                        toCenter: true
                    });

                    var popup = '';

                    popup += '<div id="NewsGetSocialPopup" class="popup_inner">';
                    popup += '<h1>Let\'s get social</h1>';
                    popup += '<p>';
                    popup += 'Follow Uloop on Facebook and Twitter and never miss a story';
                    popup += '</p>';
                    popup += '<div class="news_share_line">';
                    popup += '<div><script async src="//platform.twitter.com/widgets.js" charset="utf-8"></script><a href="https://twitter.com/uloop" class="twitter-follow-button" data-show-count="false">Follow @uloop</a></div>';
                    popup += '<div class="fb-like" data-width="450" data-href="//www.facebook.com/uloop/" data-layout="button_count" data-action="like" data-show-faces="false"></div>';
                    popup += '</div>';
                    popup += '</div>';
                    popup += '<script>FB.XFBML.parse();</script>';

                    infoPopup.setContent(popup);
                    infoPopup.open();
                });
            }

            if (window.fb_initialized) {
                openSocialPopup();
            } else {
                document.addEventListener("fb-init", function (ev) {
                    openSocialPopup();
                });
            }

            // ShowPopup('NewsGetSocialPopup');
            showed = true;
        }
        lastScrollTop = st;
    });

}

function isScrolledIntoView(elem, fullyInView) {
    var $elem = typeof(elem) == 'object' ? elem : jQ(elem);
    var $window = jQ(window);
    var fullyInView = fullyInView || false;

    var docViewTop = $window.scrollTop();
    var docViewBottom = docViewTop + $window.height();

    var elemTop = $elem.offset().top;
    var elemBottom = elemTop + $elem.height();

    if (fullyInView) {
        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    } else {
        return ((elemTop <= docViewBottom) && (elemBottom >= docViewTop));
    }
}

function openReplyByEmail(elem) {

    if (jQ(elem).parents('#custom_listing_preview').length) {
        var elem = jQ('.channel_view_page.popup_preview').find('.top_reply_form #ad-info-first-name');
        jQ('#custom_listing_preview').animate({
            scrollTop: jQ(elem).scrollTop()
        }, 100);
        jQ(elem).focus();
    }
    else if (jQ('.top_reply_form .reply-form-special, .top_reply_form .reply-form').length) {
        if (jQ('.top_reply_form .reply-form-special, .top_reply_form .reply-form').is(":visible")) {
            CMPS_ScrollToElement(jQ('.channel_view_page').find('.top_reply_form').get(0));
        }
        else {
            jQ('.top_reply .reply-button, .top_reply .reply-button-special').click();
            setTimeout(function () {
                CMPS_ScrollToElement(jQ('.channel_view_page').find('.top_reply_form').get(0));
            }, 300);
        }
    }
    else if (jQ('.top_reply input[type="submit"]').length) {
        CMPS_ScrollToElement(jQ('.top_reply input[type="submit"]').get(0));
        CMPS_blink_elem(jQ('.top_reply input[type="submit"]').get(0), 7, true);
    }
}


function openUnitFloorplan(img) {
    if (img.length) {
        var topParam = ((jQ(window).height()) / 2 + jQ(window).scrollTop() + "px");
        jQ('.loading').css('top', topParam);
        jQ('.popup_overlay, .loading').fadeIn();
        jQ('#viewFloorplans div').html('<img calss="checkUpload" src="' + img + '" onload="jQ(\'.loading\').hide(); ShowPopup(\'viewFloorplans\');" onerror="jQ(\'.loading\').hide(); ShowPopup(\'errorUploadImg\');">');
    }
}

function open360Iframe(img) {
    // if (img.length) {
    // var topParam = ((jQ(window).height()) / 2 + jQ(window).scrollTop() + "px");
    // jQ('.loading').css('top', topParam);
    // jQ('.popup_overlay, .loading').fadeIn();
    jQ('#viewFloorplans2 div').html('<iframe frameborder="0" allowfullscreen width="100%" height="100%" src="' + img + '"/></iframe>');
    ShowPopup('viewFloorplans2');
    // }
    return false;
}


///////////////////


function actionUnitDescribe(elem) {
    var id_animate_unit = jQ(elem).parents('.table_tr').attr('id').replace('unit_', '');
    var row = jQ(elem).find('.up_arrow');
    var current_deg = jQ(row).attr('deg') ? jQ(row).attr('deg') : 0;
    var new_deg = current_deg > 0 ? 0 : 90;
    jQ(row).animateRotate(current_deg, new_deg, "normal", "swing", showUnitDescribe(id_animate_unit));
}

function showUnitDescribe(id) {
    var description = jQ('#unit_data_' + id);
    if (jQ(description).is(":visible")) {
        jQ(description).slideUp(500);
    }
    else {
        jQ(description).slideDown(500);
    }
    jQ(description).show();
}

jQ.fn.animateRotate = function (start_angle, end_angle, duration, easing, complete) {
    return this.each(function () {
        var elem = jQ(this);
        jQ(elem).attr('deg', end_angle);

        jQ({deg: start_angle}).animate({deg: end_angle}, {
            duration: duration,
            easing: easing,
            step: function (now) {
                elem.css({
                    transform: 'rotate(' + now + 'deg)'
                });
            },
            complete: complete || jQ.noop
        });
    });
};


function customDescription(elem) {
    var full_info = jQ('.custom_popup_preview .full_info');
    if (jQ(full_info).is(":visible")) {
        jQ('.custom_popup_preview .short_info_action').show();
        jQ(full_info).slideUp(600);
    }
    else {
        jQ('.custom_popup_preview .short_info_action').hide();
        jQ(full_info).slideDown(600);
    }

}

function repliesDetail(e, idAd) {
    popup
    ({
        popup_id: "admin_preloader",
        speed: 400,
        close_button: false,
        hide_on_click: false
    });
    window.CodeAdapter.ajaxRequest({

        url: "index.php",
        data: "action=show_replies_detail&ad=" + idAd,
        type: "POST",
        dataType: "html",
        success: function (data) {
            jQ('#replies_detail').html(data);
            ShowPopup('replies_detail');
        }
    });
    jQ.event.fix(e).preventDefault;
}

function repliesDetailMerge(e, idAd, external, hashTitle, dateStart, dateEnd) {

    var hashTitle = "." + hashTitle,
        json;

    popup
    ({
        popup_id: "admin_preloader",
        speed: 400,
        close_button: false,
        hide_on_click: false
    });
    window.CodeAdapter.ajaxRequest({
        url: "index.php",
        data: "action=show_replies_detail&ad=" + idAd + "&dateStart=" + dateStart + "&dateEnd=" + dateEnd + "&external=" + external,
        type: "POST",
        dataType: "html",
        success: function (data) {

            json = JSON.parse(jQ(hashTitle).html());
            var additionalData = '';

            if (data.indexOf('<tbody>') !== -1) {
                additionalData += "<tr>";

                for (var i = 0; i < json.length; i++) {
                    additionalData += '<td>' + json[i]['name'] + '</td>'
                        + '<td>' + json[i]['email'] + '</td>'
                        + '<td>' + json[i]['phone'] + '</td>'
                        + '<td>' + json[i]['uformat'] + '</td>'
                }
                additionalData += "</tr>";
            } else {
                if (json !== null && typeof  json !== "undefined") {
                    for (var i = 0; i < json.length; i++) {
                        additionalData += '<div class="table_tr">'
                            + '<div class="table_td td-fixed">' + json[i]['Name'] + '</div>'
                            + '<div class="table_td td-fixed">' + json[i]['Email'] + '</div>'
                            + '<div class="table_td td-fixed">' + json[i]['Phone'] + '</div>'
                            + '<div class="table_td td-fixed">' + json[i]['Uformat'] + '</div>'
                            + '</div>';
                    }
                }
            }

            data = data.replace("<!-- replace-me -->", additionalData);

            jQ('#replies_detail > div.inner').html(data);
            ShowPopup('replies_detail');
        }
    });
    jQ.event.fix(e).preventDefault;
}

function callsDetail(e, idAd) {

    var hashTitle = "." + hashTitle,
        json;

    popup
    ({
        popup_id: "admin_preloader",
        speed: 400,
        close_button: false,
        hide_on_click: false
    });
    window.CodeAdapter.ajaxRequest({

        url: "index.php",
        data: "action=show_calls_detail&ad=" + idAd,
        type: "POST",
        dataType: "html",
        success: function (data) {
            jQ('#replies_detail').html(data);
            ShowPopup('replies_detail');
        }
    });
    jQ.event.fix(e).preventDefault;
}

function callsDetailByDate(e, idAd, dateStart, dateEnd) {

    var hashTitle = "." + hashTitle,
        json;

    popup
    ({
        popup_id: "admin_preloader",
        speed: 400,
        close_button: false,
        hide_on_click: false
    });
    window.CodeAdapter.ajaxRequest({
        url: "index.php",
        data: "action=show_calls_detail&ad=" + idAd + "&dateStart=" + dateStart + "&dateEnd=" + dateEnd,
        type: "POST",
        dataType: "html",
        success: function (data) {
            jQ('#replies_detail').html(data);
            ShowPopup('replies_detail');
        }
    });
    jQ.event.fix(e).preventDefault;
}

// show call details fro sponsored pages
function callsDetailFake(e, idAd) {
    var data, id = ".calls" + idAd,
        json = JSON.parse(jQ(id).html());

    data = "<div class='detail popup_preview'>";
    data += "<div class=\"table_tr\"> <div class=\"table_td td-fixed\"><h2>Phone</h2></div>";
    data += "<div class=\"table_td td-fixed\"><h2>Date</h2></div>";
    data += "<div class=\"table_td td-fixed\"><h2>Voicemail</h2></div></div>";

    data += "<h1>Calls</h1>";

    jQ.each(json, function (key, value) {
        data += '<div class="table_tr">';
        data += "<div class='table_td td-fixed'>" + value['from'] + "</div>";
        data += "<div class='table_td td-fixed'>" + value.date + "</div>";
        data += "<div class=\"table_td td-fixed\"><a target=\"_blank\" rel=\"nofollow\" href=\"" + value['uri'] + "\">Play</a></div>";
        data += '</div>';
    });


    data += '</div>';
    data += '</div>';

    jQ('#replies_detail').html(data);
    ShowPopup('replies_detail');

    jQ.event.fix(e).preventDefault;
}

// show replies details fro sponsored pages
function repliesDetailFake(e, idAd) {
    var data, id = ".replies" + idAd,
        json = JSON.parse(jQ(id).html());

    data = "<div class='detail popup_preview'>";
    data += "<h1>Details Of Replies</h1>";
    data += "<div class=\"table_tr\"> <div class=\"table_td td-fixed\"><h2>Name</h2></div>";
    data += "<div class=\"table_td td-fixed\"><h2>Email</h2></div>";
    data += "<div class=\"table_td td-fixed\"><h2>Phone</h2></div>";
    data += "<div class=\"table_td td-fixed\"><h2>Date</h2></div>";
    data += "</div>";

    jQ.each(json, function (key, value) {
        if ('katelyn@collegestudentapartments.com' != value['Email']) {
            data += '<div class="table_tr">'
                + '<div class="table_td td-fixed">' + value['Name'] + '</div>'
                + '<div class="table_td td-fixed">' + value['Email'] + '</div>'
                + '<div class="table_td td-fixed">' + value['Phone'] + '</div>'
                + '<div class="table_td td-fixed">' + value['Date'] + '</div>'
                + '</div>';
        }
    });

    data += '</div>';

    jQ('#replies_detail').html(data);
    ShowPopup('replies_detail');

    jQ.event.fix(e).preventDefault;
}

function isInteger(num) {
    if (isNaN(num)) {
        return false;
    }
    else
        return true;
}

function isValidPhone(phone)
{
    phone = phone.replace(/[^0-9]/g, '');
    if(phone.length != 10) {
        return false;
    } else {
        return true;
    }
}

function calcMapRoute() {
    var selectedMode = document.getElementById("mode").value;
    var request = {
        origin: ads,
        destination: college,
        travelMode: google.maps.TravelMode[selectedMode]
    };
    directionsService.route(request, function (response, status) {
        if (status == google.maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(response);
        }
    });
}

function TrackClick() {
    var params = {};

    if (getCookie('uloop_widget_clicks') != 'undefined' && getCookie('uloop_widget_clicks') != undefined && getCookie('uloop_widget_clicks') != '') {
        var clicks_arr = JSON.parse(getCookie("uloop_widget_clicks"))
        if (!clicks_arr.length)
            return false;
        params.widget_clicks = getCookie("uloop_widget_clicks");
        setCookie('uloop_widget_clicks', JSON.stringify([]), {expires: 60 * 60 * 1, path: '/'});
    }

    if (!jQuery.isEmptyObject(params)) {
        window.CodeAdapter.ajaxRequest({
            url: "ajax_content.php",
            data: 'action=tracking_click' + ( params ? '&' + jQuery.param(params) : '' ),
            dataType: "json",
            type: "POST",
            cache: "false",
            success: function (data) {
                if (typeof(data) !== 'undefined' && typeof(data.event) !== 'undefined') {
                    jQuery('body').append(data.event);
                }
            }
        });
    }
}

function Track() {
    TrackClick();

    var powered_by = jQ('#listing_powered');

    var additional_params = {};
    if (window.CodeAdapter.project_type === 'wordpress_plugin') {

        var urlSchema = urlObject();

        if (urlSchema.parameters.tlink) {
            additional_params.partner_code = urlSchema.parameters.tlink;
        }
        //console.log(document.referrer);
        additional_params.referer_full = document.referrer;
        additional_params.request = location.pathname + location.search;
        additional_params.contantID = document.getElementById('trackingContentId') ? document.getElementById('trackingContentId').value : '';
        additional_params.page = document.getElementById('trackingPage') ? document.getElementById('trackingPage').value : '';
    }


    if (window.CodeAdapter.project_type !== 'wordpress_plugin') {
        window.CodeAdapter.ajaxRequest({
            url: "ajax_content.php",
            data: 'action=tracking_page' + ( typeof(powered_by) !== 'undefined' ? '&powered_by=' + jQ(powered_by).val() : '') + ( additional_params.length ? '&' + jQuery.param(additional_params) : '' ),
            dataType: "html",
            type: "POST",
            cache: "false",
            success: function (data) {

            }
        });
    }
}


function FadeOutHotlistElementWithoutDelete(hotlistStar, delay) {
    if (window.CodeAdapter.project_type == 'wordpress_plugin') {
        jQ(hotlistStar).closest('.listing-item').fadeTo(delay, 0.2);
        jQ(hotlistStar).closest('article').fadeTo(delay, 0.2);
    }
    else {
        jQ(hotlistStar).closest('.listing-simple-item').fadeTo(delay, 0.2);
        jQ(hotlistStar).closest('.listing').fadeTo(delay, 0.2);
        jQ(hotlistStar).closest('.news-item').fadeTo(delay, 0.2);
        jQ(hotlistStar).closest('.tutor-item').fadeTo(delay, 0.2);
        jQ(hotlistStar).closest('.test-prep-item').fadeTo(delay, 0.2);
        jQ(hotlistStar).closest('.professor-item').fadeTo(delay, 0.2);

        jQ(hotlistStar).closest('.professor_item').fadeTo(delay, 0.2);
        jQ(hotlistStar).closest('.post').fadeTo(delay, 0.2);
        jQ(hotlistStar).closest('.listing-list').fadeTo(delay, 0.2);
        jQ(hotlistStar).closest('.index-post-personalized').fadeTo(delay, 0.2);
    }
}
function FadeInHotlistElementWithoutDelete(hotlistStar, delay) {
    if (window.CodeAdapter.project_type == 'wordpress_plugin') {
        jQ(hotlistStar).closest('.listing-item').fadeTo(delay, 1);
        jQ(hotlistStar).closest('article').fadeTo(delay, 1);
    }
    else {
        jQ(hotlistStar).closest('.listing-simple-item').fadeTo(delay, 1);
        jQ(hotlistStar).closest('.listing').fadeTo(delay, 1);
        jQ(hotlistStar).closest('.news-item').fadeTo(delay, 1);
        jQ(hotlistStar).closest('.tutor-item').fadeTo(delay, 1);
        jQ(hotlistStar).closest('.test-prep-item').fadeTo(delay, 1);
        jQ(hotlistStar).closest('.professor-item').fadeTo(delay, 1);

        jQ(hotlistStar).closest('.professor_item').fadeTo(delay, 1);
        jQ(hotlistStar).closest('.post').fadeTo(delay, 1);
        jQ(hotlistStar).closest('.listing-list').fadeTo(delay, 1);
        jQ(hotlistStar).closest('.index-post-personalized').fadeTo(delay, 1);
    }
}

function FadeOutHotlistElement(hotlistStar, delay) {
    jQ(hotlistStar).closest('.listing-simple-item').slideUp(delay);
    jQ(hotlistStar).closest('.listing').slideUp(delay);
    jQ(hotlistStar).closest('.news-item').slideUp(delay);
    jQ(hotlistStar).closest('.tutor-item').slideUp(delay);
    jQ(hotlistStar).closest('.test-prep-item').slideUp(delay);
    jQ(hotlistStar).closest('.professor-item').slideUp(delay);
}
function RemoveHotlistElement(hotlistStar) {
    jQ(hotlistStar).closest('.listing-simple-item').remove();
    jQ(hotlistStar).closest('.listing').remove();
    jQ(hotlistStar).closest('.news-item').remove();
    jQ(hotlistStar).closest('.tutor-item').remove();
    jQ(hotlistStar).closest('.test-prep-item').remove();
    jQ(hotlistStar).closest('.professor-item').remove();
}

function AddItemToHotlist(item_type, item_id, item_parent_id) {

    if (typeof(item_type) === 'undefined' || ( typeof(item_id) === 'undefined' && typeof(item_parent_id) === 'undefined' ) || typeof CrossStorage === 'undefined') {
        return false;
    }

    window.CodeAdapter.ajaxRequest({
        type: "GET",
        url: '/ajaxapi.php?hotlist_add_item=1&type=' + item_type + '&listing_id=' + item_id + '&parent_id=' + item_parent_id + '&' + window.sessionName + '=' + window.sessionId,
        success: function () {
        }
    });

    var hotlistArray = null;

    CrossStorage.get('hotlist', function (data) {
        hotlistArray = data;

        var hotlistRow = {listing_id: item_id, type: item_type, parent_id: item_parent_id};

        try {
            hotlistArray = JSON.parse(hotlistArray);
        } catch (e) {
            hotlistArray = [];
        }

        if (typeof(hotlistArray) === 'undefined' || !hotlistArray) {
            hotlistArray = [];
        }

        if (hotlistArray) {
            var i = 0;
            hotlistArray.reverse();
            for (var key in hotlistArray) {
                if (hotlistArray[key].parent_id == item_parent_id && hotlistArray[key].type == item_type) {
                    hotlistArray.splice(key, 1);
                }
                else {
                    i++;
                    if (i > 55) {
                        hotlistArray.splice(key, 1);
                    }
                }
            }
            hotlistArray.reverse();
        }


        hotlistArray.push(hotlistRow);

        if (jQ('.user-hotlist .count').get(0)) {
            if (hotlistArray.length) {
                jQ('.user-hotlist .count').html(hotlistArray.length);
            } else {
                jQ('.user-hotlist .count').html('');
            }
        }

        CrossStorage.set({
            hotlist: JSON.stringify(hotlistArray)
        });
    });
}

function RemoveItemFromHotlist(item_type, item_id, item_parent_id) {

    if (typeof(item_type) === 'undefined' || ( typeof(item_id) === 'undefined' && typeof(item_parent_id) === 'undefined' ) || typeof CrossStorage === 'undefined') {
        return false;
    }

    window.CodeAdapter.ajaxRequest({
        type: "GET",
        url: '/ajaxapi.php?hotlist_delete_item=1&type=' + item_type + '&listing_id=' + item_id + '&parent_id=' + item_parent_id + '&' + window.sessionName + '=' + window.sessionId,
        success: function () {
        }
    });


    var hotlistArray = null;

    CrossStorage.get('hotlist', function (data) {
        hotlistArray = data;

        try {
            hotlistArray = JSON.parse(hotlistArray);
        } catch (e) {
            hotlistArray = [];
        }

        if (typeof(hotlistArray) === 'undefined' || !hotlistArray) {
            hotlistArray = [];
        }

        if (hotlistArray) {
            for (var key in hotlistArray) {
                ;
                if (hotlistArray[key].parent_id == item_parent_id && hotlistArray[key].type == item_type) {
                    hotlistArray.splice(key, 1);
                }
            }
        }

        if (jQ('.user-hotlist .count').get(0)) {
            if (hotlistArray.length) {
                jQ('.user-hotlist .count').html(hotlistArray.length);
            } else {
                jQ('.user-hotlist .count').html('');
            }
        }

        CrossStorage.set({
            hotlist: JSON.stringify(hotlistArray)
        });
    });

//
////    var hotlistArray = JSON.parse(jQ.cookie("hotlist"));
////	var hotlistArray = JSON.parse(localStorage.getItem("hotlist"));
////    if(hotlistArray){
////        for(var key in hotlistArray["type_"+item_type]){
////            if(hotlistArray["type_"+item_type][key].listing_id == item_id){
////               hotlistArray["type_"+item_type].splice(key, 1);
////            }
////        }
////    }
////	if(hotlistArray){
////        for(var key in hotlistArray){
////            if(hotlistArray[key].listing_id == item_id && hotlistArray[key].type == item_type){
////               hotlistArray.splice(key, 1);
////            }
////        }
////    }
//
////    jQ.cookie("hotlist",JSON.stringify(hotlistArray),{expires: 365,path: '/',domain:COOKIE_DOMAIN});
////	localStorage.setItem("hotlist", JSON.stringify(hotlistArray));
//
//	var msg= {
//		method : 'remove',
//		type : item_type,
//		id : item_id,
//		parent_id : item_parent_id
//	};
//
//	storage.postMessage(JSON.stringify(msg),"*");
}


if (!Function.prototype.bind) {
    Function.prototype.bind = function (oThis) {
        if (typeof this !== "function") {
            // closest thing possible to the ECMAScript 5 internal IsCallable function
            throw new TypeError("Function.prototype.bind - what is trying to be bound is not callable");
        }

        var aArgs = Array.prototype.slice.call(arguments, 1),
            fToBind = this,
            fNOP = function () {
            },
            fBound = function () {
                return fToBind.apply(this instanceof fNOP && oThis
                    ? this
                    : oThis,
                    aArgs.concat(Array.prototype.slice.call(arguments)));
            };

        fNOP.prototype = this.prototype;
        fBound.prototype = new fNOP();

        return fBound;
    };
}


(function (jQ) {
    'use strict';

    jQ.fn.cutText = function (options) {

        var settings = jQ.extend({
            size: 240,
            omission: '...',
            ignore: true
        }, options);

        return this.each(function () {

            var textDefault,
                textTruncated,
                elements = jQ(this),
                regex = /[!-\/:-@\[-`{-~]$/,
                init = function () {
                    elements.each(function () {
                        textDefault = jQ(this).html();

                        if (textDefault.length > settings.size) {
                            textTruncated = jQ.trim(textDefault)
                                .substring(0, settings.size)
                                .split(' ')
                                .slice(0, -1)
                                .join(' ');

                            if (settings.ignore) {
                                textTruncated = textTruncated.replace(regex, '');
                            }

                            jQ(this).html(textTruncated + settings.omission);
                        }
                    });
                };
            init();
        });
    };
})(jQuery);
